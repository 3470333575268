import React from "react";

import { CURRENCY_SYMBOL, ROUTES } from "@common/Constants";
import { useNavigateTo } from "@common/Hooks";
import { DriverDetailType } from "@common/Types";
import { decimal, parseName } from "@common/Utils";
import { Col, Loader, Row, Typography } from "@components/Elements";

type InfoCardProps = {
  label: string;
  value: string | number;
};

const InfoCard = ({ label, value = "Не указано" }: InfoCardProps) => {
  return (
    <div className="flex items-start justify-between w-full">
      <p className="font-normal text-base w-2/4 text-neutral-50">{label}</p>
      <p className="font-normal text-base w-2/4 text-right text-neutral-50">
        {value}
      </p>
    </div>
  );
};

type Props = {
  driver: DriverDetailType | undefined;
};

export const PopupCard: React.FC<Props> = ({ driver }) => {
  const { navigateTo } = useNavigateTo();

  const toDriverDetails = (id: number) => {
    navigateTo(`${ROUTES.DRIVERS}/${id}`);
  };

  if (!driver?.id) {
    return (
      <Loader
        height={228}
        loading={true}
        className="bg-gray-800 min-w-[320px]"
      />
    );
  }

  return (
    <Col className="min-w-[320px] max-w-[600px]">
      <Row className="space-x-4" alignItems="items-center">
        <Typography
          variant="heading-2-b"
          color="neutral-800"
          className="bg-[#d7ad5a] w-10 h-10 rounded-md flex items-center justify-center"
        >
          {decimal(driver.rating, 1)}
        </Typography>
        <Col className="gap-1">
          <Typography
            as="h4"
            variant="body-2-b"
            onClick={() => toDriverDetails(driver.id)}
            className="cursor-pointer text-base hover:text-blue-600 font-medium whitespace-nowrap"
          >
            {parseName(driver.first_name, driver.last_name, driver.second_name)}
          </Typography>
          <Typography variant="body-2-b" as="p" color="neutral-0">
            {driver.phone}
          </Typography>
        </Col>
      </Row>
      <div className="mt-4 flex flex-col items-start space-y-3 w-full">
        <InfoCard label="Таксопарк" value={driver.park.name} />
        <InfoCard label="Номер машины" value={driver.car.plate_number} />
        <InfoCard
          label="Баланс"
          value={`${driver.balance || "Не указано"} ${CURRENCY_SYMBOL}`}
        />
        <InfoCard
          label="Автомобиль"
          value={`${driver.car.make || "Не указано"} ${
            driver.car.model || "Не указано"
          }`}
        />
        <InfoCard
          label="Тариф"
          value={driver.tariffs.map(({ name }) => name).join(", ")}
        />
        <InfoCard label="Цвет автомобиля" value={driver.car.color} />
      </div>
    </Col>
  );
};
