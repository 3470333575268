import React from "react";

type Props = {
  isOpen?: boolean;
};

export const EyeIcon = ({ isOpen = true }: Props) => {
  return isOpen ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0785 8.3115C1.97383 8.1175 1.97383 7.88216 2.0785 7.68816C3.33983 5.3555 5.66983 3.3335 7.99983 3.3335C10.3298 3.3335 12.6598 5.3555 13.9212 7.68883C14.0258 7.88283 14.0258 8.11816 13.9212 8.31216C12.6598 10.6448 10.3298 12.6668 7.99983 12.6668C5.66983 12.6668 3.33983 10.6448 2.0785 8.3115Z"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.41421 6.58579C10.1953 7.36683 10.1953 8.63317 9.41421 9.41421C8.63317 10.1953 7.36683 10.1953 6.58579 9.41421C5.80474 8.63317 5.80474 7.36683 6.58579 6.58579C7.36683 5.80474 8.63317 5.80474 9.41421 6.58579"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00039 12.6668C7.43905 12.6668 6.87705 12.5481 6.33105 12.3368"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9213 8.31201C12.66 10.6447 10.33 12.6667 8 12.6667"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7197 5.94727C13.1804 6.4866 13.5897 7.0746 13.9217 7.6886C14.0264 7.8826 14.0264 8.11793 13.9217 8.31193"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33301 12.6668L12.6663 3.3335"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.51539 9.4849C5.69539 8.6649 5.69539 7.3349 6.51539 6.5149C7.33539 5.6949 8.66539 5.6949 9.48539 6.5149"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3625 4.6375C10.3312 3.8395 9.16517 3.3335 7.99983 3.3335C5.66983 3.3335 3.33983 5.3555 2.0785 7.68883C1.97383 7.88283 1.97383 8.11816 2.0785 8.31216C2.70917 9.47816 3.6065 10.5662 4.63717 11.3635"
        stroke="#616163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
