import { Select, SelectProps } from "@mantine/core";
import React from "react";

export const CustomSelect = (props: SelectProps) => {
  return (
    <Select
      className="w-full"
      classNames={{
        input:
          "!h-[40px] border !border-neutral-border-300 !rounded-s placeholder-neutral-400 focus:shadow-none",
      }}
      {...props}
    />
  );
};
