import { useMutation } from "@tanstack/react-query";

import { promotionsService } from "@api/services";

export const useDeletePromotions = () => {
  return useMutation({
    mutationFn: (promotionId: string) =>
      promotionsService.deletePromotion(promotionId),
  });
};
