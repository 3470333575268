import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const WalletIcon = ({ strokeColor = "brand-0", ...props }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={strokeColorClasses[strokeColor]}
      {...props}
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            d="M11.2488 11.2318C11.1338 11.2326 11.0413 11.3259 11.0413 11.4409C11.0413 11.5559 11.1347 11.6493 11.2497 11.6484C11.3647 11.6484 11.458 11.5551 11.458 11.4401C11.458 11.3251 11.3647 11.2318 11.2488 11.2318"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M2.5 5.23583V5C2.5 3.61917 3.61917 2.5 5 2.5H15C16.3808 2.5 17.5 3.61917 17.5 5V12.5C17.5 13.8808 16.3808 15 15 15H14.1667"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_3"
            d="M17.5003 7.4987H14.167"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1042 17.4364C12.6708 17.7981 14.1667 16.6081 14.1667 15.0006V7.82392C14.1667 6.65976 13.3633 5.64976 12.2292 5.38809L4.54167 3.61392C3.49667 3.37226 2.5 4.16559 2.5 5.23726V13.4614C2.5 14.6256 3.30333 15.6356 4.4375 15.8973L11.1042 17.4364V17.4364Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
