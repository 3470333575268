import { omit } from "radash";

import { httpClient } from "@common/Libs/axios";
import {
  DriverReferralPromotionType,
  PromotionDetailType,
  PromotionFiltersType,
  PromotionParams,
  PromotionParticipantType,
  PromotionReportType,
  PromotionRideParticipantsType,
  PromotionSubmitType,
  PromotionType,
  RideIndividualPromotionParticipantType,
  RidePromotionReportType,
  RiderReferralPromotionType,
  TaxiParkPromotionParticipantType,
} from "@common/Types";
import { removeEmptyFields } from "@common/Utils";

import { promotionsPath } from "./paths";

/**
 * Описание методов promotion.
 */

export const promotionsService = {
  getPromotions: (
    limit: number,
    skip: number,
    filters: PromotionFiltersType,
  ) => {
    return httpClient.get<PromotionType>(promotionsPath.promotions, {
      params: removeEmptyFields({ ...filters, limit, skip }),
    });
  },
  createPromotion: (data: PromotionSubmitType) => {
    const buildedData = omit(data, ["use_for"]);
    return httpClient.post<PromotionSubmitType, PromotionSubmitType>(
      promotionsPath.promotions,
      buildedData,
    );
  },
  getPromotionDetails: (promotionId: string) =>
    httpClient.get<PromotionDetailType>(
      promotionsPath.promotion_details(promotionId),
    ),
  archivePromotion: (promotionId: string) =>
    httpClient.patch(promotionsPath.archive_promotion(promotionId), null),

  getPromotionReport: (promotionId: string) =>
    httpClient.get<PromotionReportType>(
      promotionsPath.promotion_report(promotionId),
    ),
  getReferralPromotionReports: (promotionId: string) =>
    httpClient.get<PromotionReportType>(
      promotionsPath.referral_promotion_reports(promotionId),
    ),
  getPromotionParticipants: ({ promotionId, limit, skip }: PromotionParams) =>
    httpClient.get<PromotionParticipantType>(
      promotionsPath.promotion_participants(promotionId),
      {
        params: { limit, skip },
      },
    ),
  getDriversReferralPromotionParticipants: ({
    promotionId,
    limit,
    skip,
  }: PromotionParams) =>
    httpClient.get<DriverReferralPromotionType>(
      promotionsPath.drivers_referral_promotion_participants(promotionId),
      {
        params: { limit, skip },
      },
    ),
  getRidersReferralPromotionParticipants: ({
    promotionId,
    limit,
    skip,
  }: PromotionParams) =>
    httpClient.get<RiderReferralPromotionType>(
      promotionsPath.riders_referral_promotion_participants(promotionId),
      { params: { limit, skip } },
    ),
  getRidePromotionReports: (promotionId: string) =>
    httpClient.get<RidePromotionReportType>(
      promotionsPath.ride_promotion_reports(promotionId),
    ),
  getRideIndividualPromotionParticipants: (promotionId: string) =>
    httpClient.get<RideIndividualPromotionParticipantType[]>(
      promotionsPath.ride_individual_promotion_participants(promotionId),
    ),
  getPromotionParksParticipants: ({
    skip,
    limit,
    promotionId,
  }: PromotionParams) =>
    httpClient.get<TaxiParkPromotionParticipantType[]>(
      promotionsPath.promotion_parks_participants(promotionId),
      { params: { skip, limit } },
    ),
  getRidePromotionParticipants: ({
    promotionId,
    limit,
    skip,
  }: PromotionParams) => {
    return httpClient.get<PromotionRideParticipantsType>(
      promotionsPath.ride_promotion_participants(promotionId),
      { params: { limit, skip } },
    );
  },
  deletePromotion: (promotionId: string) =>
    httpClient.delete<PromotionDetailType>(
      promotionsPath.delete_promotion(promotionId),
    ),
};
