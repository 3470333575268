import { Feature, Geometry, Point } from "geojson";
import React from "react";
import ReactMapGL, { GeolocateControl, NavigationControl } from "react-map-gl";

import { INITIAL_MAP_VIEWPORT } from "./common/constants";
import { DrawControl } from "./common/utils";
import { buildZoneInitialValue } from "./common/utils/common";

const initialViewport = {
  ...INITIAL_MAP_VIEWPORT,
  zoom: 11,
};

type Props = {
  cityName?: string;
  geometries: Geometry[];
  initialPoint: Point | null;
  onChange: (value: Geometry[]) => void;
  errors?: string | string[];
  touched?: boolean;
};

const renderErrors = (errors: string | string[]) => {
  if (Array.isArray(errors)) {
    return errors.map((error) => {
      return <span>{error}</span>;
    });
  }
  return errors;
};

export const DrawGeoJSONMap = ({
  cityName,
  geometries = [],
  initialPoint,
  onChange,
  errors,
  touched,
}: Props) => {
  const [isDrawlerInit, setDrawlerInit] = React.useState(false);
  const drawRef = React.useRef<any>();
  const [features, setFeatures] = React.useState<{ [key: string]: Feature }>(
    {},
  );

  React.useEffect(() => {
    if (isDrawlerInit) {
      onChange(
        Object.entries(features).map(([_, value]) => {
          return value.geometry;
        }),
      );
    }
  }, [isDrawlerInit, features]);

  const onManualAddToState = (newFeature: Feature) => {
    setFeatures((currFeatures) => {
      return {
        ...currFeatures,
        [newFeature.id as string]: newFeature,
      };
    });
  };

  const onCreate = React.useCallback((e: any) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        // @ts-ignore
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onUpdate = React.useCallback((e: any) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        // @ts-ignore
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = React.useCallback((e: any) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        // @ts-ignore
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const addGeoJsonToMap = () => {
    if (drawRef.current && geometries.length > 0) {
      geometries.forEach((geometry) => {
        const newFeature = {
          id: cityName,
          type: "Feature",
          properties: {},
          geometry: geometry,
        } as Feature;
        drawRef.current.add(newFeature);
        onManualAddToState(newFeature);
      });
    }
    setDrawlerInit(true);
  };

  return (
    <div className="w-full h-full flex flex-col">
      <ReactMapGL
        style={{ width: "100%", height: "100%", borderRadius: "0.375rem" }}
        initialViewState={buildZoneInitialValue(initialViewport, initialPoint)}
        interactiveLayerIds={["fill", "line"]}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken="pk.eyJ1IjoienNlcmNxdyIsImEiOiJja3F3ZGFnbnkwa2poMzFxaGZ4bnBzeDE3In0.nlGFFWpud8L6b3hR8to8RQ"
        onLoad={addGeoJsonToMap}
      >
        <GeolocateControl position={"top-left"} />
        <NavigationControl position={"top-left"} />
        <DrawControl
          drawRef={drawRef}
          position="top-left"
          displayControlsDefault={false}
          controls={{
            polygon: true,
            trash: true,
          }}
          defaultMode="draw_polygon"
          onCreate={onCreate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      </ReactMapGL>
      {touched && errors && (
        <div className={"flex flex-col text-red-600 text-sm -mt-1"}>
          {renderErrors(errors)}
        </div>
      )}
    </div>
  );
};
