import React, { FC } from "react";

import { backgroundColorClasses } from "@common/Constants";
import { cn } from "@common/Utils";

import { GridBaseProps } from "./types";

/**
 * Component for creating a grid container.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the flex container.
 * @param {string} [props.className] - Optional additional class names.
 * @param {string} [props.justifyContent='flex-start'] - Justify content alignment. Defines how items are distributed along the main axis.
 * @param {string} [props.alignItems='flex-start'] - Align items alignment. Defines how items are aligned along the cross axis.
 * @param {Columns} [props.columns='3'] - Number of grid columns.
 * @param {number} [props.minColumnWidth='3'] - Minimun columns width.
 * @param {ColorTypes} [props.bgColor] - Optional background color configuration for the flex container. Defines the color variant and level.
 * @param {keyof JSX.IntrinsicElements} [props.as] - Optional HTML element type or React component to render. Allows changing the rendered element or component.
 * @returns {JSX.Element} The rendered grid container component.
 */

export const Grid: FC<GridBaseProps> = ({
  children,
  className,
  justifyContent = "justify-stretch",
  alignItems = "items-stretch",
  columns = 3,
  minColumnWidth = "0px",
  maxColumnWidth = "1fr",
  as: Component = "div",
  bgColor,
}) => {
  const bgColorClass = bgColor ? backgroundColorClasses[bgColor] : "";
  const gridTemplateColumns = `repeat(${columns},minmax(${minColumnWidth},${maxColumnWidth}))`;

  return (
    <Component
      style={{
        gridTemplateColumns: gridTemplateColumns,
      }}
      className={cn(
        "grid gap-x-3 gap-y-4 truncate",
        bgColorClass,
        justifyContent,
        alignItems,
        className,
      )}
    >
      {children}
    </Component>
  );
};
