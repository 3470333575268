import React, { ReactNode } from "react";

import { SvgIconComponentType } from "@common/Types";
import { cn } from "@common/Utils";
import { Flexbox, Row, Typography } from "@components/Elements";

type Props = {
  children: ReactNode;
  title?: string;
  Icon?: SvgIconComponentType;
  className?: string;
  renderRowItem?: JSX.Element;
  renderRightItem?: JSX.Element;
  onClickTitle?: () => void;
};

export const Block = ({
  children,
  Icon,
  title,
  className,
  renderRowItem,
  renderRightItem,
  onClickTitle,
}: Props) => {
  return (
    <div
      className={cn(
        className,
        "shadow-md p-4 bg-neutral-bg-0 space-y-4 rounded-lg divide-y divide-neutral-border-100",
      )}
    >
      {title && (
        <Flexbox
          justifyContent="justify-between"
          alignItems="items-center"
          className="gap-2"
        >
          <Row alignItems="items-center" className="gap-4 flex-wrap">
            {Icon && <Icon />}

            <Typography
              color="neutral-800"
              variant="heading-2-b"
              as="h2"
              className={cn(
                { "cursor-pointer": onClickTitle },
                "font-semibold",
              )}
              onClick={onClickTitle}
            >
              {title}
            </Typography>

            {renderRowItem}
          </Row>

          {renderRightItem}
        </Flexbox>
      )}

      {children}
    </div>
  );
};
