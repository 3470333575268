import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { CorporateClientsFinanceReportParams } from "@common/Types";

export const useGetCorporateClientsFinancialReports = (
  params: CorporateClientsFinanceReportParams,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CORPORATE_CLIENTS_FINANCIAL_REPORTS, params],
    queryFn: async () =>
      await corporateClientsService.getCorporateClientsReports(params),
  });
};
