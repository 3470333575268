/**
 * Сервисы для tariffs
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.tariffs;
const srcDashboard = appConfig.api.dashboard;

export const tariffsPath = {
  tariffs: `${src}/`,
  tariffsById: `${srcDashboard}/tariffs/`,
  updateTariff: (id: IDType) => `${srcDashboard}/tariffs/${id}`,
};
