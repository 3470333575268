import React from "react";

import { backgroundColorClasses } from "@common/Constants";
import { ColorClassKey } from "@common/Types";

import { Col, Row } from "../flexbox";
import { Typography } from "../typography";

type Props = {
  label?: string;
  color?: ColorClassKey;
  showLine?: boolean;
};

export const Pointer = ({
  label,
  color = "brand-400",
  showLine = false,
}: Props) => {
  const colorClass = color ? backgroundColorClasses[color] : "";

  return (
    <Row alignItems="items-start">
      <Col alignItems="items-center" className="inline-flex mt-1">
        <Row alignItems="items-center" className="gap-2">
          <div
            className={`${colorClass} w-[18px] h-[18px] rounded-full flex justify-center items-center`}
          >
            <div className="bg-brand-bg-0 rounded-full w-1.5 h-1.5"></div>
          </div>
        </Row>

        {showLine && (
          <div className="h-[33px] w-0 border-l-2 border-dashed border-gray-300"></div>
        )}
      </Col>

      <div className="ml-2 w-full">
        <Typography color="neutral-800">{label}</Typography>

        {showLine && <hr className="mt-4 border-neutral-bg-75" />}
      </div>
    </Row>
  );
};
