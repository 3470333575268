import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { IDType } from "@common/Types";

type Props = {
  rideId?: IDType;
};

export const useGetRidesEvents = ({ rideId }: Props) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDES_EVENTS, rideId],
    queryFn: () => ridesService.getRidesEvents(rideId),
  });
};
