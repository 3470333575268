import React from "react";

type Props = {
  className?: string;
};

export const MoneyIcon = ({ className }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.1793 12.971C15.1063 13.898 15.1063 15.402 14.1793 16.33C13.2523 17.257 11.7483 17.257 10.8202 16.33C9.89325 15.403 9.89325 13.899 10.8202 12.971C11.7483 12.043 13.2523 12.043 14.1793 12.971"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18H6.5"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 11H17.5"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 20.9H5.4C4.351 20.9 3.5 20.049 3.5 19V9.9C3.5 8.851 4.351 8 5.4 8H19.6C20.649 8 21.5 8.851 21.5 9.9V18.9C21.5 20.005 20.605 20.9 19.5 20.9Z"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 5H5.5"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 2H6.5"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
