import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";

type Props = {
  promotion_id: string;
};

export const useGetPromotionDetails = ({ promotion_id }: Props) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PROMOTION_DETAILS, promotion_id],
    queryFn: async () =>
      await promotionsService.getPromotionDetails(promotion_id),
  });
};
