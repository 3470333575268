/**
 * Сервисы для drivers
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.dashboard;
const driversSrc = appConfig.api.drivers;
const promotionsSrc = appConfig.api.promotions;

export const driverPath = {
  locations: `${src}/drivers/locations/`,
  support_applications: `${driversSrc}/support-applications/`,
  drivers: `${src}/drivers/`,
  driver_events: `${src}/drivers/destinations/statuses/`,
  create_driver: `${src}/drivers/application/registration/`,
  update_driver: (driverId?: IDType) => `${src}/drivers/${driverId}/`,
  get_driver_detail: (driverId?: IDType) => `${src}/drivers/${driverId}/`,
  set_driver_balance: (driverId?: IDType) =>
    `${src}/drivers/${driverId}/transactions/`,
  set_driver_bonus: (driverId?: IDType) => `${src}/drivers/${driverId}/bonus/`,
  set_driver_activation: (driverId?: IDType) =>
    `${src}/drivers/${driverId}/allow-rides/`,
  get_driver_statistics: (driverId?: IDType) =>
    `${src}/drivers/${driverId}/statistics/`,
  get_driver_statistics_ride: (driverId?: IDType) =>
    `${promotionsSrc}/drivers/${driverId}/ride/`,
  get_driver_transactions: (driverId?: IDType) =>
    `${src}/drivers/${driverId}/transactions/`,
  get_driver_change_history: (driverId?: IDType) =>
    `${src}/drivers/${driverId}/history/`,
  send_photo_control: `${src}/drivers/photo-control/invalidate/`,
  // statistics
  driver_statistics_rides: () => `${src}/drivers/statistics/rides/`,
  driver_ride_statistics: (id: IDType) =>
    `${src}/drivers/${id}/ride-statistics/`,
  driver_status_statistics: `${src}/drivers/status/statistics/daily`,
  driver_status_statistics_average: `${src}/drivers/status/statistics/average`,
  driver_status_statistics_total: `${src}/drivers/status/statistics/total`,
  driver_status_count: () => `${src}/drivers/status/count/`,
  driver_action: (id: string) => `${src}/drivers/${id}/actions/`,
  driver_group_balance: `${src}/drivers/transactions/`,
};
