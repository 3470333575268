import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { citiesService } from "@api/services";
import { CitiesType } from "@common/Types";

export const useGetCities = (skip: number, limit: number) => {
  const { data, ...query } = useQuery<CitiesType>({
    queryKey: [QUERY_KEYS.CITIES, skip, limit],
    queryFn: async () => await citiesService.getCities(skip, limit),
  });

  return { response: data, ...query };
};
