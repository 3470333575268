export type TabsViewClassesType = {
  tabActive: string;
  tabControl: string;
  tabsListWrapper: string;
  tabsList: string;
  body: string;
};

type TabViewClassTypes = {
  [key: string]: TabsViewClassesType;
};

export const TABS_VIEW_CLASSES: TabViewClassTypes = {
  default: {
    tabActive:
      "text-neutral-text-800 border-b-[3px] border-brand-bg-800 text-body-2",
    tabControl: "flex-shrink-0 text-neutral-text-500 text-body-2",
    tabsListWrapper: "border-b-[0px]",
    tabsList: "flex-nowrap overflow-x-auto no-scrollbar",
    body: "pt-[0px]",
  },
};

// segment tabs
export type SegmentTabViewClassesType = {
  root?: string;
  control?: string;
  input?: string;
  label?: string;
  indicator?: string;
  innerLabel?: string;
};

type SegmentTabViewClassTypes = {
  [key: string]: SegmentTabViewClassesType;
};

export const SEGMENT_TAB_VIEW_CLASSES: SegmentTabViewClassTypes = {
  default: {
    root: "bg-neutral-bg-75 overflow-visible",
  },
};
