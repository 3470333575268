import { useFormikContext } from "formik";
import { debounce, isEqual } from "radash";
import { useCallback, useEffect, useState } from "react";

/**
 * `SubmitListener` is a component that listens for changes in form values and submits the form
 * when the values are modified, ensuring that the form is valid before submission.
 *
 * It utilizes Formik's context to get the current form values, initial values, and validity status,
 * and uses a debounced function to trigger the form submission.
 *
 * @example
 * <Formik>
 *   <Form>
 *     <SubmitListener />
 *
 *     // other form fields
 *   </Form>
 * </Formik>
 */

export const SubmitListener = () => {
  const { values, initialValues, isValid, submitForm } = useFormikContext();
  const [lastValues, setLastValues] = useState(values);

  const confirmForm = useCallback(debounce({ delay: 600 }, submitForm), [
    submitForm,
  ]);

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, values);
    const valuesEqualInitialValues = isEqual(initialValues, values);

    if (!valuesEqualLastValues) {
      setLastValues(values);
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues && isValid) {
      confirmForm();
    }
  }, [values, lastValues, initialValues, isValid]);

  return null;
};
