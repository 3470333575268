import { isArray } from "radash";
import React, { ReactNode } from "react";

import { Col, Typography } from "@components/Elements";

type Params<T> = {
  value: ReactNode;
  name: string;
};

type Props<T> = {
  params: Params<T>[] | Params<T>;
};

export const Description = <T,>({ params }: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map((param: Params<T>, index: number) => {
        const { name, value } = param;

        return (
          <Col key={`${String(name)}-${index}`} className="gap-1 flex-wrap">
            <Typography
              color="neutral-500"
              variant="body-2-b"
              as="dt"
              className="font-medium"
            >
              {name}
            </Typography>

            <Typography
              color="neutral-800"
              className="whitespace-normal"
              as="dd"
            >
              {value ?? "-"}
            </Typography>
          </Col>
        );
      })}
    </>
  );
};
