import React from "react";

type Props = {
  onClick: () => void;
};

export const DoubleCheckIcon = ({ onClick }: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 17.5001H4.16667C3.24583 17.5001 2.5 16.7542 2.5 15.8334V8.33341C2.5 7.41258 3.24583 6.66675 4.16667 6.66675H11.6667C12.5875 6.66675 13.3333 7.41258 13.3333 8.33341V15.8334C13.3333 16.7542 12.5875 17.5001 11.6667 17.5001Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9375 12.4242L7.2575 13.7442L10.1383 10.8625"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83301 6.66667V4.16667C5.83301 3.24583 6.57884 2.5 7.49967 2.5H15.833C16.7538 2.5 17.4997 3.24583 17.4997 4.16667V12.5C17.4997 13.4208 16.7538 14.1667 15.833 14.1667H13.333"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
