import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { FraudControlRidesFilters } from "@common/Types";

type Params = FraudControlRidesFilters & {
  enabled?: boolean;
};
export const useGetAntiFrauds = ({ enabled = true, ...params }: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ANTI_FRAUDS, params],
    queryFn: async () => await antiFraudsService.getAntiFraud(params),
    enabled,
  });
};
