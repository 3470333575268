import React from "react";

import { rotateIconClassnames } from "@common/Constants";
import { SvgIconComponentType } from "@common/Types";

export const ChevronIcon: SvgIconComponentType = ({ rotation = "bottom" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={rotateIconClassnames[rotation]}
    >
      <rect width="28" height="28" rx="14" fill="#EBEBEB" />
      <path
        d="M10 15L14 11L18 15"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
