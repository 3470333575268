// Motor
import { isTargetNavi } from "@assets/Flags";

export const MOTOR_CURRENCY_SYMBOL = "₸";

// Navi
export const NAVI_CURRENCY_SYMBOL = "сом";

export const CURRENCY_SYMBOL = isTargetNavi
  ? NAVI_CURRENCY_SYMBOL
  : MOTOR_CURRENCY_SYMBOL;

export const IDENTIFICATION_NUMBER = isTargetNavi ? "ИНН" : "ИИН";

export const DISPATCHER_NAME = isTargetNavi ? "Navi" : "Motor";

export const TABLE_LIMIT = 17;
export const MOBILE_WIDTH = 640;
export const DESKTOP_START_WIDTH = 768;
export const CURRENCY_CODE = isTargetNavi ? "KGS" : "KZT";
export const COUNTRY_CODE = isTargetNavi ? "KGZ" : "KAZ";
export const CURRENCY = "тенге";
export const MAIN_LANG = "ru";
export const MAIN_LANG_LABEL = "рус";
export const ALTERNATIVE_LANG = "kz";
export const ALTERNATIVE_LANG_LABEL = "каз";
export const INN_LIMIT = isTargetNavi ? 14 : 12;
export const REFETCH_INTERVAL = 15000;


export const MOTOR_ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=reviro.taxi.kz";

export const MOTOR_IOS_APP_LINK =
  "https://apps.apple.com/kg/app/motor-taxi/id6450346747";

export const MOTOR_DRIVER_APP_LINK =
  "https://play.google.com/store/apps/details?id=reviro.taxi.motor";

export const NAVI_ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=reviro.taxi.rider&hl=ru&gl=US";

export const NAVI_IOS_APP_LINK =
  "https://apps.apple.com/kg/app/navi/id1585148582";

export const NAVI_DRIVER_APP_LINK =
  "https://play.google.com/store/apps/details?id=reviro.taxi.driver";

export const ANDROID_APP_LINK = isTargetNavi
  ? NAVI_ANDROID_APP_LINK
  : MOTOR_ANDROID_APP_LINK;

export const IOS_APP_LINK = isTargetNavi
  ? NAVI_IOS_APP_LINK
  : MOTOR_IOS_APP_LINK;

export const ANDROID_DRIVER_APP_LINK = isTargetNavi
  ? NAVI_DRIVER_APP_LINK
  : MOTOR_DRIVER_APP_LINK;
