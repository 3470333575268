import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { permissionsService } from "@api/services";
import { IDType } from "@common/Types";

/**
 * Получение списка accesses
 */

type Params = {
  cityId?: IDType;
  enabled?: boolean;
};

export const useGetPermissions = ({ enabled = true, cityId = "" }: Params) => {
  const {
    isError,
    error,
    data: response,
    ...other
  } = useQuery({
    queryKey: [QUERY_KEYS.ACCESSES, cityId],
    queryFn: () => permissionsService.getPermissions(cityId),
    enabled,
  });

  return { isError, error, response, ...other };
};
