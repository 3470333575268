import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { RefundPaymentQueryParams } from "@common/Types";

export const useRefundPayment = () => {
  return useMutation({
    mutationFn: (param: RefundPaymentQueryParams) =>
      ridesService.refundPayment(param),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RIDES_DETAIL] });
      toast.success("Деньги успешно возвращены");
    },
  });
};
