import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";

type Props = {
  promotion_id: string;
};

export const useGetRideIndividualPromotionParticipants = ({
  promotion_id,
}: Props) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDE_INDIVIDUAL_PROMOTION_PARTICIPANTS, promotion_id],
    queryFn: async () =>
      await promotionsService.getRideIndividualPromotionParticipants(
        promotion_id,
      ),
  });
};
