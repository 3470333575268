import { TimeInput as MantineTimeInput } from "@mantine/dates";
import React, { useEffect } from "react";

import { Props, TimeInputView } from "./types";
import { VIEW_CLASSES } from "./variants";

/**
 * TimeInput component renders a time input field with customizable labels, description,
 * icon, and error handling. It utilizes the Mantine `TimeInput` component as its base.
 *
 * @param {Props} props - The properties for the TimeInput component.
 * @returns {JSX.Element} The rendered TimeInput component.
 */

export const TimeInput: React.FC<Props> = ({
  view = "default",
  defaultValue,
  value,
  error,
  disabled,
  classNames,
  excludeTime,
  onBlur,
  ...props
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  let currentView = view as TimeInputView;
  const getValue = value ?? defaultValue;

  useEffect(() => {
    // hide select time in TimeInput
    if (divRef?.current && excludeTime) {
      const hourInput = divRef.current.querySelector(
        `input[aria-label="${excludeTime}"]`,
      );

      const nextSibling = hourInput?.nextElementSibling;

      const hasInput = hourInput instanceof HTMLInputElement;
      const hasNextSibling = nextSibling instanceof HTMLDivElement;

      if (hasInput) {
        hourInput.style.display = "none";
      }

      if (hasNextSibling) {
        nextSibling.style.display = "none";
      }
    }
  }, [excludeTime]);

  if (disabled) {
    currentView = view === "no-border" ? "no-border-disabled" : "disabled";
  } else if (error) {
    currentView = "error";
  }

  return (
    <div ref={divRef} className="w-full">
      <MantineTimeInput
        hoursLabel="hours"
        minutesLabel="minutes"
        value={getValue}
        error={error}
        disabled={disabled}
        classNames={{
          ...VIEW_CLASSES[currentView],
          ...classNames,
        }}
        {...props}
      />
    </div>
  );
};
