/**
 * Сервисы для park applications
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const parkApplicationsPath = {
  parks: `${src}/parks/applications/`,
  activate: (id: number) => `${src}/parks/applications/${id}`,
};
