import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";

export const useGetCorporateClients = (skip: number, limit: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CORPORATE_CLIENTS, skip, limit],
    queryFn: async () =>
      await corporateClientsService.getCorporateClients(skip, limit),
  });
};
