import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { FRAUD_STATUSES } from "@common/Constants";
import { ToggleReRegistrationUserParams } from "@common/Types";

export const useToggleReRegistrationUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ToggleReRegistrationUserParams) =>
      await antiFraudsService.toggleReRegistrationUser(data),
    onSuccess: (_, data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ANTI_FRAUDS_RE_REGISTRATION_USERS],
      });

      if (data.action === FRAUD_STATUSES.APPROVE) {
        toast.success("Пользователь активирован");
      } else if (data.action === FRAUD_STATUSES.DECLINE) {
        toast.success("Пользователь отклонен");
      }
    },
  });
};
