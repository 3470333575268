import { LayerProps } from "react-map-gl";

export const fillLayer: LayerProps = {
  id: "fill",
  type: "fill",
  paint: {
    "fill-color": "#0085FF",
    "fill-opacity": 0.1,
  },
};

export const lineLayer: LayerProps = {
  id: "line",
  type: "line",
  paint: {
    "line-width": 1,
    "line-color": "#0085FF",
  },
};
