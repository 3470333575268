import { AxiosRequestConfig } from "axios";

import { useLogout } from "@api/queries";
import { authPaths } from "@api/services/auth/paths";
import { TokenData } from "@common/Types";
import {
  getAccessTokenLocalStorage,
  getRefreshTokenLocalStorage,
  setRefreshTokenLocalStorage,
  setTokenLocalStorage,
  showErrorToast,
} from "@common/Utils";

import { apiClient } from "./apiClient";

// Extend AxiosRequestConfig to include custom properties
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _isRefreshing?: boolean;
}

const MAX_RETRY_COUNT = 5;
let _IS_START_REFRESH_TOKEN = false;

// Function to logout user
function logout() {
  useLogout();
}

// Function to generate headers for requests
function getRequestHeaders(config: CustomAxiosRequestConfig) {
  const accessToken = getAccessTokenLocalStorage();
  const baseHeaders = { "Content-Type": "application/json" };

  // Determine whether to include the Authorization header
  const authHeader = config._isRefreshing
    ? {}
    : { Authorization: `Bearer ${accessToken}` };

  return {
    ...config.headers,
    ...baseHeaders,
    ...authHeader,
  };
}

// Request interceptor to attach authorization header
apiClient.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    return { ...config, headers: getRequestHeaders(config) };
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor to handle token refresh
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const access_token = getAccessTokenLocalStorage();
    const refresh_token = getRefreshTokenLocalStorage();

    let _retryCount = 0;
    const hasToken = access_token && refresh_token;
    const isUnauthorized = error.response?.status === 401;
    const isNotExpiredRetryCount = _retryCount <= MAX_RETRY_COUNT;

    if (
      isUnauthorized &&
      hasToken &&
      isNotExpiredRetryCount &&
      !_IS_START_REFRESH_TOKEN
    ) {
      // increase the number of attempts on error
      _retryCount += 1;
      _IS_START_REFRESH_TOKEN = true;

      try {
        const JWTRequest: TokenData = await refreshTokenRequest();

        if (JWTRequest.status >= 200) {
          // reset retry count on success
          _retryCount = 0;
          _IS_START_REFRESH_TOKEN = false;

          // Update tokens in local storage
          setRefreshTokenLocalStorage(JWTRequest.data.refresh_token);
          setTokenLocalStorage(JWTRequest.data.access_token);

          // Update headers with new access token
          apiClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${JWTRequest.data.access_token}`;

          // Retry the original request with new headers
          return apiClient(originalRequest);
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    if (isUnauthorized && _retryCount >= MAX_RETRY_COUNT) {
      showErrorToast(error);
      logout();
    }

    return Promise.reject(error);
  },
);

// Function to refresh the access token
async function refreshTokenRequest() {
  const refreshToken = getRefreshTokenLocalStorage();
  const config: CustomAxiosRequestConfig = {
    headers: { Authorization: `Bearer ${refreshToken}` },
    _isRefreshing: true,
  };

  return await apiClient.post(authPaths.refresh_token, {}, config);
}

export { apiClient as fetcher };
