import React from "react";

import { CreateOperationFormType } from "@common/Types";
import { Button } from "@components/Elements";

import { WalletIcon } from "./assests/index";
import { CreateOperationForm } from "./components/shared";

type Props = {
  className?: string;
  size?: "small" | "medium" | "large";
  onSubmit: (values: CreateOperationFormType) => Promise<void>;
  onClick?: () => void;
  close: () => void;
  open: boolean;
  title: string;
  disabled?: boolean;
  bonuses?: number;
  user: {
    type: "rider" | "driver";
    first_name?: string;
    last_name?: string;
    second_name?: string;
  };
};

export const CreateBonusOperationButton: React.FC<Props> = ({
  onClick,
  close,
  open,
  onSubmit,
  disabled,
  title,
  bonuses,
}: Props) => {
  return (
    <>
      <Button
        view="gray"
        icon={<WalletIcon color="neutral-900" />}
        onClick={onClick}
        disabled={disabled}
        className="disabled:pointer-events-none"
      >
        Бонусы: {bonuses || 0}
      </Button>

      <CreateOperationForm
        title={title}
        show={open}
        close={close}
        onSubmit={onSubmit}
        type={"bonus"}
      />
    </>
  );
};
