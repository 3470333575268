import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { ridersService } from "@api/services";
import { TRANSACTION_TYPES } from "@common/Constants";
import { CreateOperationFormType } from "@common/Types";

export type CreateRiderBonusVariables = {
  rider_id: string;
  values: CreateOperationFormType;
};

export const useCreateRiderBonus = () => {
  const q = useQueryClient();
  return useMutation({
    mutationFn: ({ rider_id, values }: CreateRiderBonusVariables) =>
      ridersService.createRiderBonuses(rider_id, values),
    onSuccess: (values) => {
      q.invalidateQueries({ queryKey: [QUERY_KEYS.RIDER_DETAIL] });
      toast.success(
        `Бонусы для пользователя успешно ${
          values.transaction_type === TRANSACTION_TYPES.WITHDRAW
            ? "списаны"
            : "пополнены"
        }`,
      );
    },
  });
};
