import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { CreateCorporateClientEmployeeType, IDType } from "@common/Types";

type Params = {
  data: CreateCorporateClientEmployeeType;
  id: IDType;
};

export const useCreateCorporateClientEmployee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }: Params) =>
      corporateClientsService.createCorporateClientsEmployee(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CORPORATE_CLIENTS_EMPLOYEES],
      });
      toast.success(`Сотрудник успешно добавлен`);
    },
  });
};
