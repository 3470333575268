import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { IDType } from "@common/Types";

export const useGetCorporateClientDetail = (id: IDType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CORPORATE_CLIENTS_DETAIL, id],
    queryFn: async () =>
      await corporateClientsService.getCorporateClientsDetail(id),
    enabled: !!id,
  });
};
