import { httpClient } from "@common/Libs/axios";
import {
  AdminCityInfo,
  CitiesType,
  CityCreateType,
  CityDetailType,
  EditCityQueryParms,
  IDType,
} from "@common/Types";

import { citiesPath } from "./paths";

export const citiesService = {
  getCities: (skip: number, limit: number) =>
    httpClient.get<CitiesType>(citiesPath.cities, {
      params: { skip, limit },
    }),

  getCity: (id?: IDType) => httpClient.get<CityDetailType>(citiesPath.city(id)),

  getAdminCities: () => httpClient.get<AdminCityInfo>(citiesPath.admin_city),

  deleteCity: (id?: IDType) => httpClient.delete(citiesPath.city(id)),

  editCity: (params: EditCityQueryParms) => {
    const { id, data } = params;

    return httpClient.patch(citiesPath.city(id), data);
  },

  createCity: (data: Partial<CityCreateType>) =>
    httpClient.post<CityDetailType, Partial<CityCreateType>>(
      citiesPath.cities,
      data,
    ),
};
