import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import {
  FRAUD_PERMISSION_ACTION,
  FraudControlPermissionsStatusEdit,
} from "@common/Types";

export const useSetAntiFraudPermissionsStatus = () => {
  return useMutation({
    mutationFn: (params: FraudControlPermissionsStatusEdit) =>
      antiFraudsService.setAntiFraudPermissionsStatus(params),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ANTI_FRAUDS_PERMISSIONS],
      });

      const text = {
        [FRAUD_PERMISSION_ACTION.APPROVE]: "Одобрен",
        [FRAUD_PERMISSION_ACTION.REJECT]: "Отклонен",
      };

      toast.success(text[variables.action]);
    },
  });
};
