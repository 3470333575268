/**
 * Сервисы для cities
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.dashboard;

export const citiesPath = {
  cities: `${src}/cities/`,
  city: (id?: IDType) => `${src}/cities/${id}`,
  admin_city: `${src}/admin/city/`,
};
