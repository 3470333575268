import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { parksService } from "@api/services";
import { LandingParkApplicationType } from "@common/Types";

export const useCreateLandingParkApplications = () => {
  let loading_toast = "";

  return useMutation({
    mutationKey: [QUERY_KEYS.LANDING_PARK_APPLICATION],
    mutationFn: async (application: LandingParkApplicationType) => {
      loading_toast = toast.loading("Отправляем вашу заявку...");

      return await parksService.sendParkApplication(application);
    },
    onError: () => {
      toast.remove();
    },
    onSuccess: () => {
      toast.remove();
      toast.success("Ваша заявка успешно доставлена!", {
        id: loading_toast,
      });
    },
    retry: 1,
  });
};
