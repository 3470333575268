import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { TransactionParams } from "@common/Types";

export const useGetCorporateClientsTransactions = (
  params: TransactionParams,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CORPORATE_CLIENTS_TRANSACTIONS, params],
    queryFn: async () =>
      await corporateClientsService.getCorporateClientsTransactions(params),
  });
};
