import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { parkApplicationsService } from "@api/services";
import { ParkApplicationParams } from "@common/Types";

export const useActiveParkApplication = () => {
  const q = useQueryClient();
  return useMutation({
    mutationFn: ({ id, status }: ParkApplicationParams) =>
      parkApplicationsService.activateParkApplication({ id, status }),
    onSuccess: () => {
      toast.success("Заявка от таксопарка переведена в одобренные");
      q.invalidateQueries({ queryKey: [QUERY_KEYS.PARK_APPLICATIONS] });
    },
  });
};
