import { useField } from "formik";
import { isArray } from "radash";
import React, { Fragment, ReactNode, useState } from "react";

import { ChevronIcon } from "@assets/Icons";
import { ColorClassKey, CommonFieldParams, ROLES } from "@common/Types";
import { cn, getUserRole } from "@common/Utils";
import { Button, Flexbox, Row, Switch, Typography } from "@components/Elements";

type Props<T> = {
  children?: ReactNode;
  params: CommonFieldParams<T>[] | CommonFieldParams<T>;
  isEdit?: boolean;
  bgColor?: ColorClassKey;
  className?: string;
  onChange?: (field: Exclude<keyof T, "">, value: number) => void;
};

export const FormSwitchField = <T,>({
  children,
  params,
  isEdit = true,
  bgColor = "neutral-50",
  onChange,
}: Props<T>) => {
  const role = getUserRole() as ROLES;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const transformedParams = isArray(params) ? params : [params];

  const toggleCollapse = () => setIsCollapsed((prev) => !prev);

  return (
    <>
      {transformedParams.map((limit: CommonFieldParams<T>, index: number) => {
        const {
          name,
          field,
          accessRoles = [],
          Icon,
          className,
          collapsible,
        } = limit;

        const [{ value }, , { setValue }] = useField(String(field));

        const currentValue = Number(value) || 0;

        const onSwitchChange = (value: boolean) => {
          const parsedValue = value ? 1 : 0;

          setValue(parsedValue);
          if (onChange) onChange(field, parsedValue);
        };

        if (!!accessRoles.length && !accessRoles.includes(role)) {
          return null;
        }

        return (
          <Fragment key={`${String(field)}-${index}`}>
            <Flexbox
              justifyContent="justify-between"
              alignItems="items-center"
              bgColor={bgColor}
              className={cn("w-full py-2.5 px-4 rounded-lg", className)}
            >
              <Row alignItems="items-center" className="gap-[12px]">
                {Icon}
                <Typography color="neutral-800">{name}</Typography>
              </Row>

              <Row alignItems="items-center" className="gap-[14px]">
                <Switch
                  checked={currentValue}
                  disabled={!isEdit}
                  className="disabled:checked:bg-brand-bg-500"
                  onChange={onSwitchChange}
                />

                {collapsible && (
                  <Button view="none" onClick={toggleCollapse}>
                    <ChevronIcon rotation={isCollapsed ? "bottom" : "top"} />
                  </Button>
                )}
              </Row>
            </Flexbox>

            {collapsible ? (!isCollapsed ? children : null) : children}
          </Fragment>
        );
      })}
    </>
  );
};
