import { httpClient } from "@common/Libs/axios";
import {
  FraudControlPermissionsFilters,
  FraudControlPermissionsStatusEdit,
  FraudControlPermissionType,
  FraudControlReasonType,
  FraudControlRidesFilters,
  FraudControlType,
  QueryParams,
  ReasonAniFraudsQueryParams,
  ReRegistrationUsersDetailType,
  ReRegistrationUsersType,
  ToggleReRegistrationUserParams,
  VerdictAniFraudsQueryParams,
} from "@common/Types";
import { removeEmptyFields } from "@common/Utils";

import { antiFraudPath } from "./paths";

/**
 * Описание методов anti fraud.
 */

export const antiFraudsService = {
  getAntiFraud: (filters: FraudControlRidesFilters) => {
    const cleanFilters = removeEmptyFields(filters);

    return httpClient.get<FraudControlType>(antiFraudPath.anti_fraud, {
      params: cleanFilters,
    });
  },

  getReasonAntiFraud: ({ paymentId }: ReasonAniFraudsQueryParams) => {
    return httpClient.get<FraudControlReasonType[]>(
      antiFraudPath.anti_fraud_reason({ paymentId }),
    );
  },

  getAntiFraudPermissions: (filters: FraudControlPermissionsFilters) => {
    const cleanFilters = removeEmptyFields(filters);

    return httpClient.get<FraudControlPermissionType>(
      antiFraudPath.anti_fraud_permissions,
      { params: cleanFilters },
    );
  },

  setAntiFraudPermissionsStatus: (data: FraudControlPermissionsStatusEdit) => {
    return httpClient.post<
      FraudControlPermissionType,
      FraudControlPermissionsStatusEdit
    >(antiFraudPath.anti_fraud_permissions, data);
  },

  verdictAntiFraud: ({
    blocked_payment_id,
    type,
    description,
  }: VerdictAniFraudsQueryParams) => {
    return httpClient.post(antiFraudPath.anti_fraud, {
      blocked_payment_id,
      set_to_status: type,
      description,
    });
  },
  getReRegistrationUsers: (query: QueryParams) => {
    const params = removeEmptyFields({ ...query.filters, ...query });

    return httpClient.get<ReRegistrationUsersType>(
      antiFraudPath.re_registration,
      { params: params },
    );
  },

  toggleReRegistrationUser: (data: ToggleReRegistrationUserParams) => {
    return httpClient.post<
      ReRegistrationUsersDetailType,
      ToggleReRegistrationUserParams
    >(antiFraudPath.toggle_re_registration_users(data.user_id), data);
  },
};
