import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { REFETCH_INTERVAL } from "@common/Constants";
import { QueryParams, RidesEnrouteFiltersType } from "@common/Types";

export const useGetDriverEvents = (
  params: QueryParams<RidesEnrouteFiltersType>,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.DRIVER_EVENTS, params],
    queryFn: () => driversService.getDriverEvents(params),
    refetchInterval: REFETCH_INTERVAL,
  });
};
