import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { QueryParams } from "@common/Types";


export const useGetReRegistrationFraudUsers = (query: QueryParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ANTI_FRAUDS_RE_REGISTRATION_USERS, query],
    queryFn: async () => await antiFraudsService.getReRegistrationUsers(query),
  });
};
