import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { IDType } from "@common/Types";

export const useFinishRide = () => {
  const q = useQueryClient();
  return useMutation({
    mutationFn: (ride_id: IDType) => ridesService.finish_ride(ride_id),
    onSuccess: () => {
      q.invalidateQueries({ queryKey: [QUERY_KEYS.RIDE_DETAIL] });
      toast.success("Cтатус успешно изменен");
    },
  });
};
