import { httpClient } from "@common/Libs/axios";
import {
  LandingParkApplicationType,
  ParkParams,
  ParksDetailType,
  ParksShort,
  ParksType,
} from "@common/Types";
import { removeEmptyFields } from "@common/Utils";

import { parksPath } from "./paths";

/**
 * Описание методов parks.
 */

export const parksService = {
  getParksShort: (cityId?: string | number) =>
    httpClient.get<ParksShort[]>(parksPath.parks_short, {
      params: cityId ? { city_id: cityId } : {},
    }),
  sendParkApplication: (application: LandingParkApplicationType) =>
    httpClient.post(parksPath.parks_applications, application),
  getParks: ({ limit, skip, search }: ParkParams) => {
    const buildedData = removeEmptyFields({ limit, skip, search });
    return httpClient.get<ParksType>(parksPath.parks, {
      params: buildedData,
    });
  },
  getParkDetail: (id: string) =>
    httpClient.get<ParksDetailType>(parksPath.park_detail(id)),
};
