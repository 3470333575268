import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";

type Props = {
  promotion_id: string;
};

export const useGetPromotionReports = ({ promotion_id }: Props) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PROMOTION_REPORT, promotion_id],
    queryFn: async () =>
      await promotionsService.getPromotionReport(promotion_id),
  });
};
