import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { FraudControlPermissionsFilters } from "@common/Types";

type Params = FraudControlPermissionsFilters & {
  enabled?: boolean;
};

export const useGetAntiFraudsPermissions = ({
  enabled = true,
  ...params
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ANTI_FRAUDS_PERMISSIONS, params],
    queryFn: () => antiFraudsService.getAntiFraudPermissions(params),
    enabled,
  });
};
