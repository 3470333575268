export const QUERY_KEYS = {
  ACCESSES: "ACCESSES",
  USER: "USER",
  LANDING_TARIFFS: "LANDING_TARIFFS",
  LANDING_NEWS: "LANDING_NEWS",
  LANDING_CITIES: "LANDING_CITIES",
  DRIVERS_LOCATION: "DRIVERS_LOCATION",
  DRIVERS: "DRIVERS",
  DRIVER_DETAIL: "DRIVER_DETAIL",
  DRIVER_STATISTICS: "DRIVER_STATISTICS",
  DRIVER_STATISTICS_RIDE: "DRIVER_STATISTICS_RIDE",
  DRIVER_STATISTICS_RIDES: "DRIVER_STATISTICS_RIDES",
  DRIVER_TRANSACTIONS: "DRIVER_TRANSACTIONS",
  DRIVER_CHANGE_HISTORY: "DRIVER_CHANGE_HISTORY",
  DRIVERS_STATUS_STATISTICS: "DRIVERS_STATUS_STATISTICS",
  DRIVERS_STATUS_STATISTICS_AVERAGE: "DRIVERS_STATUS_STATISTICS_AVERAGE",
  DRIVERS_STATUS_STATISTICS_TOTAL: "DRIVERS_STATUS_STATISTICS_TOTAL",
  DRIVERS_RIDE_STATISTICS: "DRIVERS_RIDE_STATISTICS",
  DRIVER_STATUS_COUNT: "DRIVER_STATUS_COUNT",
  PARKS_SHORT: "PARKS_SHORT",
  PARKS: "PARKS",
  PARKS_DETAIL: "PARKS_DETAIL",
  LANDING_PARK_APPLICATION: "LANDING_PARK_APPLICATION",
  LANDING_DRIVER_APPLICATION: "LANDING_DRIVER_APPLICATION",
  PAYMENTS_SAVE: "PAYMENTS_SAVE",
  PARK_APPLICATIONS: "PARK_APPLICATIONS",

  ANTI_FRAUDS: "ANTI_FRAUDS",
  ANTI_FRAUDS_PERMISSIONS: "ANTI_FRAUDS_PERMISSIONS",
  ANTI_FRAUDS_REASON: "ANTI_FRAUDS_REASON",
  ANTI_FRAUDS_RE_REGISTRATION_USERS: "ANTI_FRAUDS_RE_REGISTRATION_USERS",

  CONFIGS: "CONFIGS",
  CITY_FEES: "CITY_FEES",
  PARK_FEES: "PARK_FEES",

  REPORTS: "REPORTS",
  PAYMENTS: "PAYMENTS",
  REFUND_HISTORY: "REFUND_HISTORY",
  ACCOUNT_REPORTS: "ACCOUNT_REPORTS",

  VERSIONS: "VERSIONS",
  INTROS: "INTROS",

  RIDES: "RIDES",
  RIDES_DETAIL: "RIDES_DETAIL",
  RIDES_REVIEW: "RIDES_REVIEW",
  RIDES_EVENTS: "RIDES_EVENTS",

  PROMOTIONS: "PROMOTIONS",
  PROMOTION_DETAILS: "PROMOTION_DETAILS",
  PROMOTION_REPORT: "PROMOTION_REPORT",
  RIDE_PROMOTION_REPORTS: "RIDE_PROMOTION_REPORTS",
  PROMOTION_PARTICIPANTS: "PROMOTION_PARTICIPANTS",
  RIDERS_REFERRAL_PROMOTION_PARTICIPANTS:
    "RIDERS_REFERRAL_PROMOTION_PARTICIPANTS",
  DRIVERS_REFERRAL_PROMOTION_PARTICIPANTS:
    "DRIVERS_REFERRAL_PROMOTION_PARTICIPANTS",
  REFERRAL_PROMOTION_REPORTS: "REFERRAL_PROMOTION_REPORTS",
  RIDE_INDIVIDUAL_PROMOTION_PARTICIPANTS:
    "RIDE_INDIVIDUAL_PROMOTION_PARTICIPANTS",
  PROMOTION_PARKS_PARTICIPANTS: "PROMOTION_PARKS_PARTICIPANTS",
  RIDE_PROMOTION_PARTICIPANTS: "PROMOTION_PARKS_PARTICIPANTS",
  CITIES: "CITIES",
  CITIES_DETAILS: "CITIES_DETAILS",
  CITIES_ADMIN: "CITIES_ADMIN",
  SEND_CODE: "SEND_CODE",
  VERIFY_CODE: "VERIFY_CODE",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  TARIFFS: "TARIFFS",
  TARIFFS_BY_ID: "TARIFFS_BY_ID",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST: "SUPPORT_RIDERS_CHAT_MESSAGE_LIST",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST_EXACT:
    "SUPPORT_RIDERS_CHAT_MESSAGE_LIST_EXACT",
  SUPPORT_RIDERS_CHAT_MESSAGE_LIST_BY_ID:
    "SUPPORT_RIDERS_CHAT_MESSAGE_LIST_BY_ID",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST: "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_EXACT:
    "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_EXACT",
  SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_BY_ID:
    "SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_BY_ID",
  NEWS: "NEWS",
  NEWS_DETAILS: "NEWS_DETAILS",
  DRIVER_APPLICATIONS: "DRIVER_APPLICATIONS",
  DRIVER_APPLICATIONS_DETAIL: "DRIVER_APPLICATIONS_DETAIL",
  LANDING_DRIVER_APPLICATIONS: "LANDING_DRIVER_APPLICATIONS",
  DASHBOARD_USERS: "DASHBOARD_USERS",
  TRANSACTIONS: "TRANSACTIONS",
  TRANSACTIONS_DRIVERS_REPORTS: "TRANSACTIONS_DRIVERS_REPORTS",
  TRANSACTIONS_WITHDRAW: "TRANSACTIONS_WITHDRAW",
  ADMIN_TRANSACTIONS: "ADMIN_TRANSACTIONS",
  ADMIN_TRANSACTIONS_BANK_ACCOUNTS: "ADMIN_TRANSACTIONS_BANK_ACCOUNTS",

  DRIVER_EVENTS: "DRIVER_EVENTS",

  RIDERS: "RIDERS",
  RIDER_DETAIL: "RIDER_DETAIL",
  RIDER_EDIT_HISTORY: "RIDER_EDIT_HISTORY",
  FAMILY_ACCOUNT_DETAIL: "FAMILY_ACCOUNT_DETAIL",
  RIDER_RIDES: "RIDER_RIDES",

  ZONES: "ZONES",
  ZONES_PRISES: "ZONES_PRISES",

  CORPORATE_CLIENTS: "CORPORATE_CLIENTS",
  RIDE_DETAIL: "RIDE_DETAIL",
  CORPORATE_CLIENTS_DETAIL: "CORPORATE_CLIENTS_DETAIL",
  CORPORATE_CLIENTS_EMPLOYEES: "CORPORATE_CLIENTS_EMPLOYEES",
  CORPORATE_CLIENTS_TRANSACTIONS: "CORPORATE_CLIENTS_TRANSACTIONS",
  CORPORATE_CLIENTS_FINANCIAL_REPORTS: "CORPORATE_CLIENTS_FINANCIAL_REPORTS",
};
