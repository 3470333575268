import React from "react";

import { FilterTabType } from "@common/Types";

import { CheckboxList } from "../checkbox-list";

import { DriverStatusIcon } from "./assets/icons";

type Props<T> = {
  filters: T;
  setFilters: (filters: T) => void;
  options: FilterTabType[];
};

export const DriverStatusFilter = <T,>({
  filters,
  setFilters,
  options,
}: Props<T>) => {
  return (
    <CheckboxList
      singleChoice
      MainIcon={<DriverStatusIcon />}
      title={"Статус"}
      filters={filters}
      setFilters={setFilters}
      field={"status" as keyof T}
      options={options}
    />
  );
};
