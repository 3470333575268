import { Group, Pagination as MantinePagination, Space } from "@mantine/core";
import React from "react";

type Props = {
  skip: number;
  setSkip: (page: number) => void;
  limit: number;
  length: number | undefined;
};

export const Pagination: React.FC<Props> = ({
  skip,
  setSkip,
  limit,
  length,
}) => {
  const page = skip / limit + 1;

  const onChange = (page: number) => {
    setSkip((page - 1) * limit);
  };

  if (!length || length <= limit) {
    return null;
  }

  return (
    <>
      <Space h={"md"} />
      <Group position={"right"}>
        <MantinePagination
          total={Math.ceil(length / limit)}
          page={page}
          onChange={onChange}
          size="md"
          classNames={{
            active: "bg-brand-bg-1000",
          }}
          withEdges
        />
      </Group>
    </>
  );
};
