import { Children, isValidElement, ReactNode } from "react";

import {
  FilterTabType,
  PredefinedDateRangeType,
  TariffType,
} from "@common/Types";
import {
  getAll,
  getMonth,
  getToday,
  getWeek,
  getYesterday,
} from "@common/Utils";

import { SearchFilters } from "../components/search-filter";

import { FilterChipType } from "./types";

export const hasSearchComponent = (children: ReactNode) => {
  let searchComponent: ReactNode | null = null;
  const remainingChildren: ReactNode[] = [];

  Children.forEach(children, (child) => {
    if (isValidElement(child) && child.type === SearchFilters) {
      searchComponent = child;
    } else {
      remainingChildren.push(child);
    }
  });

  return { searchComponent, remainingChildren };
};

export const buildTariffsFilter = (parks: TariffType[]): FilterTabType[] => {
  return parks.map((park) => ({
    label: park.name,
    value: park.id.toString(),
  }));
};

// helper for filter chips (applied filter list)
export const updateChips = (
  chips: FilterChipType[],
  field: string,
  label: string,
) => {
  // Check if the chip with the specified field exists
  const chipExists = chips.some((chip) => chip.field === field);

  if (chipExists) {
    // If the label is empty, remove the chip
    if (label === "") {
      return chips.filter((chip) => chip.field !== field);
    }

    // Otherwise, update the chip's list with the new label
    return chips.map((chip) =>
      chip.field === field ? { ...chip, list: [label] } : chip,
    );
  }

  // If the chip doesn't exist and the label is not empty, add a new chip
  if (label !== "") {
    return [...chips, { field, list: [label] }];
  }

  // If the chip doesn't exist and the label is empty, return the chips unchanged
  return chips;
};

// util for date filter: get value of date range
export const getDateRangeKey = (
  dateRange: string[],
  dateFormat?: string,
): PredefinedDateRangeType => {
  const ranges = {
    today: getToday(dateFormat),
    yesterday: getYesterday(dateFormat),
    week: getWeek(dateFormat),
    month: getMonth(dateFormat),
    all: getAll(),
  };

  const [start, end] = dateRange;

  // Compare with predefined ranges
  for (const [key, value] of Object.entries(ranges)) {
    if (start === value[0] && end === value[1]) {
      return key as PredefinedDateRangeType;
    }
  }

  return "custom";
};
