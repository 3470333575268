import { httpClient } from "@common/Libs/axios";
import {
  ParkApplicationParams,
  ParkApplicationsParams,
  ParkApplicationsType,
} from "@common/Types";

import { parkApplicationsPath } from "./paths";

/**
 * Описание методов park applications.
 */

export const parkApplicationsService = {
  getParksApplications: ({ filters, skip, limit }: ParkApplicationsParams) =>
    httpClient.get<ParkApplicationsType>(parkApplicationsPath.parks, {
      params: { skip, limit, ...filters },
    }),
  activateParkApplication: ({ id, status }: ParkApplicationParams) =>
    httpClient.put(parkApplicationsPath.activate(id), { status }),
};
