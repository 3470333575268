import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { CorporateEmployeesParams } from "@common/Types";

export const useGetCorporateClientEmployees = (
  params: CorporateEmployeesParams,
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.CORPORATE_CLIENTS_EMPLOYEES, params],
    queryFn: async () =>
      await corporateClientsService.getCorporateClientEmployees(params),
  });
};
