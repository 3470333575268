import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { citiesService } from "@api/services";
import { CityCreateType } from "@common/Types";

export const useCreateCity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<CityCreateType>) =>
      citiesService.createCity(data),

    onSuccess: (data) => {
      toast.success(`Город '${data?.name}' успешно создан`);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CITIES] });
    },
  });
};
