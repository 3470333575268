import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";

export const useGetReferralPromotionReports = (promotionId: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.REFERRAL_PROMOTION_REPORTS, promotionId],
    queryFn: async () =>
      await promotionsService.getReferralPromotionReports(promotionId),
  });
};
