import { PredefinedRanges } from "@common/Types";

export const DATE_FORMAT_DD_MM_YYYY_MM_SS = "dd.MM.yyyy HH:mm:ss";
export const DATE_FORMAT_dd_MM_yyyy = "dd-MM-yyyy";
export const DATE_FORMAT_MM_dd_yyyy = "MM-dd-yyyy";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_yyyy_MM_dd = "yyyy-MM-dd";
export const DATE_FORMAT_HH_mm_ss = "HH:mm:ss";
export const DATE_FORMAT_dd_MM_yyyy_DOT = "dd.MM.yyyy";
export const DATE_FORMAT_DD_MM_YYYY_HH_MM = "dd.MM.yyyy HH:mm";

export const RangeDateVariants: PredefinedRanges[] = [
  {
    label: "Сегодня",
    value: "today",
  },
  {
    label: "Вчера",
    value: "yesterday",
  },
  {
    label: "Неделя",
    value: "week",
  },
  {
    label: "Месяц",
    value: "month",
  },
  {
    label: "Все время",
    value: "all",
  },
];
