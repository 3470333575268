import { Form, Formik, FormikHelpers } from "formik";
import React from "react";

import { useDimension } from "@common/Hooks";

import { FilterValueType } from "./common/types";
import { ChipsFilter } from "./components/chips-filter";
import { CitiesFilter } from "./components/cities-filter";
import { CustomSelect } from "./components/custom-select";
import { DateFilter } from "./components/date-filter";
import { DesktopFilter } from "./components/desktop-filter";
import { MobileFilter } from "./components/mobile-filter";
import { ParksFilter } from "./components/park-filters";
import { PaymentTypeFilter } from "./components/payment-type-filter";
import { SearchFilters } from "./components/search-filter";
import { StatusFilter } from "./components/status-filter";
import { TariffsFilter } from "./components/tariff-filter";

type Props<T extends object> = {
  initialValues: FilterValueType<T>;
  onSubmit: (
    values: FilterValueType<T>,
    formikHelpers: FormikHelpers<FilterValueType<T>>,
  ) => void;
  children: React.ReactNode | React.ReactNode[];
};

export const Filters = <T extends object>({
  initialValues,
  onSubmit,
  children,
}: Props<T>) => {
  const { isDesktop } = useDimension();

  const Component = React.useMemo(
    () => (isDesktop ? DesktopFilter : MobileFilter),
    [isDesktop],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form className="w-full">
        <Component>{children}</Component>
      </Form>
    </Formik>
  );
};

Filters.Search = SearchFilters;
Filters.Cities = CitiesFilter;
Filters.Tariffs = TariffsFilter;
Filters.Parks = ParksFilter;
Filters.Status = StatusFilter;
Filters.CustomSelect = CustomSelect;
Filters.Chips = ChipsFilter;
Filters.PaymentType = PaymentTypeFilter;
Filters.Date = DateFilter;
