import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { IDType } from "@common/Types";

type Param = {
  rideId?: IDType;
};

export const useCancelRide = () => {
  return useMutation({
    mutationFn: ({ rideId }: Param) => ridesService.cancelRide(rideId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.RIDES_DETAIL] });
      toast.success("Поездка успешно отменена");
    },
  });
};
