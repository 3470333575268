import React from "react";

import { fillColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const FilledStarIcon = ({ size = 24, fillColor = "brand-0" }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      className={fillColorClasses[fillColor]}
    >
      <path d="M7.5 10.7938L11.3625 13.125L10.3375 8.73125L13.75 5.775L9.25625 5.39375L7.5 1.25L5.74375 5.39375L1.25 5.775L4.6625 8.73125L3.6375 13.125L7.5 10.7938Z" />
    </svg>
  );
};
