import React from "react";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

export const Table = ({ children, className }: Props) => {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="align-middle inline-block w-full">
          <div className="shadow-md bg-white rounded-[8px] sm:rounded-md overflow-x-scroll">
            <table
              className={`overflow-y-scroll divide-y divide-neutral-200 table-fixed bg-white md:w-full ${className}`}
            >
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
