import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { zonesService } from "@api/services";
import { ConfigsType } from "@common/Types";

type Params = Partial<Pick<ConfigsType, "surge_pricing_enabled">>;

export const useToggleSurgePricingZone = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Params) => zonesService.toggleSurgePricingZone(params),

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONFIGS] });

      toast.success(
        `Зона повышенного спроса ${
          variables.surge_pricing_enabled ? "включена" : "выключена"
        }`,
      );
    },
  });
};
