import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";

type Props = SVGProps;

export const ChatIcon = ({ size = 20, strokeColor = "brand-0" }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={strokeColorClasses[strokeColor]}
    >
      <path
        d="M13 17H18C19.657 17 21 15.657 21 14V6C21 4.343 19.657 3 18 3H6C4.343 3 3 4.343 3 6V14C3 15.657 4.343 17 6 17H8V21L13 17"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
