import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { citiesService } from "@api/services";
import { EditCityQueryParms } from "@common/Types";

export const useEditCity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: EditCityQueryParms) => citiesService.editCity(params),

    onSuccess: () => {
      toast.success(`Город успешно изменен`);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CITIES] });
    },
    retry: 0,
  });
};
