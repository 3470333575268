import { appConfig } from "@api/config";
import { IDType, ReasonAniFraudsQueryParams } from "@common/Types";

/**
 * Сервисы для anti fraud
 */

const src = appConfig.api.antifraud;
const srcDashboard = appConfig.api.dashboard;

export const antiFraudPath = {
  anti_fraud: `${src}/blocked-payments/`,
  anti_fraud_permissions: `${srcDashboard}/antifraud/promotions/`,
  anti_fraud_reason: ({ paymentId }: ReasonAniFraudsQueryParams) =>
    `${src}/${paymentId}/admin-descriptions/`,

  re_registration: `${src}/users/`,

  toggle_re_registration_users: (user_id: IDType) => `${src}/users/${user_id}`,
};
