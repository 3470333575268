import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { CreateCorporateClientType, IDType } from "@common/Types";

export const useEditCorporateClient = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: IDType;
      data: CreateCorporateClientType;
    }) => corporateClientsService.editCorporateClients(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CORPORATE_CLIENTS_DETAIL],
      });
      toast.success(`Клиент успешно изменён`);
    },
  });
};
