import { httpClient } from "@common/Libs/axios";
import {
  AdminTransaction,
  AdminTransactionsParams,
  BankAccountBalanceParams,
  BankAccountBalanceResponseType,
  BankAccountListType,
  BankAccountQuery,
  TransactionMainType,
  TransactionsFiltersType,
  TransactionsParams,
  WithdrawTransactionsType,
} from "@common/Types";
import {
  buildCommonDateFilters,
  buildDateRangeFiltersValues,
  buildDateRangeFromTextRange,
  commaSerializer,
  removeEmptyFilterValues,
} from "@common/Utils";

import { transactionsPath } from "./paths";

/**
 * Описание методов transactions.
 */

export const TransactionsService = {
  getTransactions: ({ filters, skip, limit }: TransactionsParams) => {
    const filteredDate = buildCommonDateFilters(filters, [
      "date_range",
      "city_id",
      "type",
      "select_range",
      "search",
    ]);

    return httpClient.get<TransactionMainType>(transactionsPath.transactions, {
      params: { ...filteredDate, skip, limit },
      paramsSerializer: commaSerializer,
    });
  },
  getDriverTransactionsReports: (filters: TransactionsFiltersType) => {
    const transFormedDateRange =
      buildDateRangeFromTextRange("select_range")(filters);

    const filteredData = buildDateRangeFiltersValues(transFormedDateRange);

    return httpClient.get<BlobPart>(transactionsPath.reports, {
      params: filteredData,
      paramsSerializer: commaSerializer,
      responseType: "arraybuffer",
    });
  },
  getWithdrawTransactions: ({ filters, skip, limit }: TransactionsParams) => {
    const filteredDate = buildCommonDateFilters(filters, [
      "date_range",
      "city_id",
      "select_range",
      "search",
      "provider",
    ]);

    return httpClient.get<WithdrawTransactionsType>(transactionsPath.withdraw, {
      params: { ...filteredDate, skip, limit },
      paramsSerializer: commaSerializer,
    });
  },
  getAdminTransactions: ({ filters, skip, limit }: AdminTransactionsParams) => {
    const filteredDate = removeEmptyFilterValues(filters);

    return httpClient.get<AdminTransaction>(
      transactionsPath.adminTransactions,
      {
        params: { ...filteredDate, skip, limit },
        paramsSerializer: commaSerializer,
      },
    );
  },

  getBankAccounts: (query: BankAccountQuery) => {
    return httpClient.get<BankAccountListType>(transactionsPath.bankAccounts, {
      params: removeEmptyFilterValues(query),
    });
  },

  setBankAccountBalance: ({ account_id, body }: BankAccountBalanceParams) => {
    return httpClient.post<
      BankAccountBalanceResponseType,
      BankAccountBalanceParams["body"]
    >(transactionsPath.bankAccountsBalance(account_id), body);
  },
};
