import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { TransactionsService } from "@api/services";
import { TransactionsParams } from "@common/Types";

type Params = TransactionsParams & {
  refetchInterval?: number;
};

export const useGetWithdrawTransactions = ({
  filters,
  skip,
  limit,
  refetchInterval,
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TRANSACTIONS_WITHDRAW, filters, skip, limit],
    queryFn: async () =>
      await TransactionsService.getWithdrawTransactions({
        filters,
        skip,
        limit,
      }),
    refetchInterval,
  });
};
