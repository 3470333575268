import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import { FRAUD_STATUSES } from "@common/Constants";
import { queryClient } from "@common/Libs/react_query";
import { VerdictAniFraudsQueryParams } from "@common/Types";

type Params = VerdictAniFraudsQueryParams;

export const useVerdictAntiFrauds = () => {
  return useMutation({
    mutationFn: async (params: Params) =>
      await antiFraudsService.verdictAntiFraud(params),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ANTI_FRAUDS] });

      if (variables.type === FRAUD_STATUSES.UNLOCKED) {
        toast.success("Вы одобрили поездку");
      }
      if (variables.type === FRAUD_STATUSES.BLOCKED) {
        toast.success("Вы отклонили поездку");
      }
    },
  });
};
