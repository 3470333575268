import { CloseButton, Input } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { MagnifyingGlass } from "phosphor-react";
import React from "react";
import { twMerge } from "tailwind-merge";

export type KeysOfType<T, TProp> = {
  [P in keyof T]: T[P] extends TProp ? P : never;
}[keyof T];

type Props<Filters> = {
  chat?: string;
  placeholder: string;
  filters: Filters;
  setFilters: (values: Filters) => void;
  field: keyof Filters;
  width?: string;
  className?: string;
};

export const Search = <Filters,>({
  chat,
  placeholder,
  filters,
  setFilters,
  field,
  width = "288px",
  className,
}: Props<Filters>) => {
  const value = filters[field] as unknown as string;
  const [localValue, setLocalValue] = React.useState<string>(value || "");
  const [debouncedValue] = useDebouncedValue(localValue, 300);

  React.useEffect(() => {
    setFilters({
      ...filters,
      [field]: debouncedValue,
    });
  }, [debouncedValue]);

  const onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(value);
  };

  return (
    <div className={twMerge("self-center", className)}>
      <Input
        icon={<MagnifyingGlass size={24} color={"#616163"} />}
        styles={{ input: { width: width } }}
        classNames={{
          input:
            "rounded-[4px] h-[40px] border border-neutral-300 py-[7px] text-body-1-b  pl-[43px]",
          icon: "pl-[14px]",
        }}
        placeholder={placeholder}
        value={localValue}
        onChange={onChange}
        rightSection={
          chat && value ? (
            <CloseButton
              onClick={() => setLocalValue("")}
              aria-label="Close modal"
            />
          ) : null
        }
      />
    </div>
  );
};
