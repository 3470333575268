import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { citiesService } from "@api/services";
import { IDType } from "@common/Types";

type Params = {
  id?: IDType;
};

export const useDeleteCity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: Params) => citiesService.deleteCity(params.id),

    onSuccess: () => {
      toast.success(`Город успешно удален`);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CITIES] });
    },
  });
};
