import { useMutation } from "@tanstack/react-query";

import { promotionsService } from "@api/services";

export const useEditArchivePromotionById = () => {
  return useMutation({
    mutationFn: (promotionId: string) =>
      promotionsService.archivePromotion(promotionId),
  });
};
