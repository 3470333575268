import { AxiosError } from "axios";
import toast from "react-hot-toast";

const duration = 2000;

export const showErrorToast = (error: AxiosError, statusCode?: number) => {
  const data = error.response?.data;

  if (statusCode === 500) {
    toast.error("Internal Server Error", { duration });
  } else if (data?.message) {
    toast.error(data.message, { duration });
  } else if (data?.detail) {
    if (Array.isArray(data.detail)) {
      data.detail.forEach((err: { msg: string }) =>
        toast.error(err.msg, { duration }),
      );
    } else {
      toast.error(data.detail, { duration });
    }
  } else {
    toast.error(error.message, { duration });
  }
};
