import { CommonTypedFieldParams } from "@common/Types";
import { formatSecondsToTimeString } from "@common/Utils";

type Props<T> = {
  param: CommonTypedFieldParams<T>;
  currentValue: string | number | Date | null;
  value: string | number | Date | null;
};
export const renderTextValue = <T>({
  param,
  currentValue,
  value,
}: Props<T>) => {
  switch (param.type) {
    case "text": {
      const { symbol = "" } = param;

      return currentValue != null ? `${currentValue}${symbol}` : "-";
    }
    case "time": {
      return formatSecondsToTimeString(value as number);
    }
  }
};
