/**
 * Сервисы для zones
 */

import { appConfig } from "@api/config";

const src = appConfig.api.dashboard;

export const zonesPath = {
  zones: `${src}/zones/`,
  zones_prices: `${src}/zones/prices`,
  configs: `${src}/configs`,
};
