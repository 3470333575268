import { Group, Select, Title } from "@mantine/core";
import React from "react";

import { useGetCities } from "@api/queries";
import { CityIcon } from "@assets/Icons";
import { buildCitiesOption } from "@common/Utils";

type Props = {
  filterValue?: string | number;
  setFilterValue: (value: string) => void;
};

export const CitiesFilterMobile = ({ filterValue, setFilterValue }: Props) => {
  const { response } = useGetCities(0, 50);

  return (
    <Group spacing={"xs"} direction="column">
      <Title order={5}>Город</Title>
      <Select
        icon={<CityIcon />}
        placeholder="Город"
        data={buildCitiesOption(response?.items)}
        value={String(filterValue)}
        onChange={setFilterValue}
      />
    </Group>
  );
};
