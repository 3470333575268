import { AnimatePresence, motion } from "framer-motion";
import { CaretLeft, CaretRight, X } from "phosphor-react";
import React, { Dispatch, SetStateAction, useState } from "react";

import { DialogModal } from "@components/Elements";

type Props = {
  photos: string[];
  show: boolean;
  setShow: (show: boolean) => void;
  currentImg: number;
  setCurrentImg: Dispatch<SetStateAction<number>>;
};

export const ImageViewer: React.FC<Props> = ({
  photos,
  show,
  setShow,
  currentImg,
  setCurrentImg,
}) => {
  const [rotate, setRotate] = useState("top");
  const onClose = () => {
    setShow(false);
  };

  const rotateHandler = () => {
    switch (rotate) {
      case "top":
        return setRotate("right");
      case "right":
        return setRotate("bot");
      case "bot":
        return setRotate("left");
      default:
        return setRotate("top");
    }
  };

  const showNextImage = () => {
    if (photos) {
      if (currentImg === 0) {
        setCurrentImg(photos.length - 1);
      } else {
        setCurrentImg(currentImg - 1);
      }
    }
  };

  const showPreviousImage = () => {
    if (photos) {
      if (currentImg === photos.length - 1) {
        setCurrentImg(0);
      } else {
        setCurrentImg(currentImg + 1);
      }
    }
  };

  return (
    <DialogModal show={show} close={onClose} scrollable={false}>
      <div
        className={
          "inline-block w-full max-w-5xl transition-all transform z-100 h-screen"
        }
      >
        <motion.button
          whileHover={{ scale: 1.1 }}
          className={
            "absolute right-0 top-10 bg-white z-30 focus:outline-none rounded-md p-1"
          }
          onClick={onClose}
        >
          <X size={28} />
        </motion.button>

        <div className="flex items-center h-full w-full justify-center">
          {photos.length > 1 && (
            <motion.button
              whileHover={{ x: -5 }}
              whileTap={{ x: -10 }}
              className={"p-2 bg-gray-600 rounded-xl focus:outline-none"}
              onClick={showNextImage}
            >
              <CaretLeft size={38} color={"#fff"} />
            </motion.button>
          )}

          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={currentImg}
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 20 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.25 }}
              className={"h-full rounded-xl overflow-hidden"}
            >
              <motion.img
                onClick={rotateHandler}
                src={photos[currentImg]}
                alt={"img"}
                className={`${
                  rotate === "right"
                    ? "rotate-90"
                    : rotate === "bot"
                    ? "rotate-180"
                    : rotate === "left"
                    ? "-rotate-90"
                    : "-rotate-0"
                } bg-center object-contain transform transition-transform w-full rounded-md h-full w-full cursor-pointer p-10`}
              />
            </motion.div>
          </AnimatePresence>

          {photos.length > 1 && (
            <motion.button
              whileHover={{ x: 5 }}
              whileTap={{ x: 10 }}
              className={"p-2 bg-gray-600 rounded-xl focus:outline-none"}
              onClick={showPreviousImage}
            >
              <CaretRight size={38} color={"#fff"} />
            </motion.button>
          )}
        </div>
      </div>
    </DialogModal>
  );
};
