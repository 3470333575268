import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";

type Props = {
  promotion_id: string;
};

export const useGetRidePromotionReports = ({ promotion_id }: Props) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDE_PROMOTION_REPORTS, promotion_id],
    queryFn: async () =>
      await promotionsService.getRidePromotionReports(promotion_id),
  });
};
