import { httpClient } from "@common/Libs/axios";
import { AccessPages, IDType } from "@common/Types";

import { permissionsPath } from "./paths";

/**
 * Описание методов для permissions.
 */

export const permissionsService = {
  getPermissions: (cityId?: IDType) =>
    httpClient.get<AccessPages[]>(permissionsPath.permission, {
      params: cityId ? { city_id: cityId } : {},
    }),

  editAccess: (accessList: Omit<AccessPages, "name">[]) =>
    httpClient.put(permissionsPath.permission, accessList),
};
