/**
 * Сервисы для rides
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.dashboard;

export const ridesPath = {
  rides: `${src}/rides/`,
  rides_detail: (rideId?: IDType) => `${src}/rides/${rideId}/`,
  rides_events: (rideId?: IDType) => `${src}/rides/${rideId}/events/`,
  refund_payment: (rideId?: IDType) => `${src}/rides/${rideId}/refund/`,
  rides_review: `${src}/rides/reviews/`,
  ride_detail: (rideId: IDType) => `${src}/rides/${rideId}/`,
  cancel_ride: (rideId: IDType) => `${src}/rides/${rideId}/cancel/`,
  set_status_to_rider: (rideId: IDType) => `${src}/rides/${rideId}/at_rider/`,
  start: (rideId: IDType) => `${src}/rides/${rideId}/go/`,
  finish: (rideId: IDType) => `${src}/rides/${rideId}/finish/`,
};
