import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { CreateCorporateClientType } from "@common/Types";

export const useCreateCorporateClient = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateCorporateClientType) =>
      corporateClientsService.createCorporateClients(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CORPORATE_CLIENTS],
      });
      toast.success(`Корпоративный клиент: '${data.title}' успешно создан`);
    },
  });
};
