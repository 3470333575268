import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { limitsService } from "@api/services";
import { FeesType, IDType } from "@common/Types";

type Params = {
  cityId?: IDType;
  enabled?: boolean;
};

export const useGetCityFees = ({ cityId, enabled = true }: Params) => {
  return useQuery<FeesType>({
    queryKey: [QUERY_KEYS.CITY_FEES, cityId],
    queryFn: async () => await limitsService.getCityFees(cityId),
    enabled,
  });
};
