import { isTargetNavi } from "@assets/Flags";

export type PaymentProviderOptions =
  | "cash"
  | "epay"
  | "corporate"
  | "kaspi"
  | "family"
  | "payler"
  | "mbank";

export const PAYMENT_PROVIDER_LABEL: Record<PaymentProviderType, string> = {
  cash: "Наличными",
  epay: "Картой",
  corporate: "Корпоративный аккаунт",
  kaspi: "Каспи",
  family: "Семейный аккаунт",
  payler: "Картой",
  mbank: "Мбанк",
};

export type PaymentProviderType =
  | "cash"
  | "epay"
  | "corporate"
  | "kaspi"
  | "family"
  | "payler"
  | "mbank";

const bankProviderFilterTypeMotor = [
  { label: "Visa/MasterCard", value: "freedom" },
  { label: "Halyk", value: "epay" },
  { label: "Altyn-i", value: "altyn" },
];

const bankProviderFilterTypeNavi = [{ label: "Mbank", value: "mbank" }];

export const BANK_PROVIDER_FILTER_TYPE = isTargetNavi
  ? bankProviderFilterTypeNavi
  : bankProviderFilterTypeMotor;
