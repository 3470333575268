import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionParams } from "@common/Types";

export const useGetRidersReferralPromotionParticipants = ({
  promotionId,
  limit,
  skip,
}: PromotionParams) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.RIDERS_REFERRAL_PROMOTION_PARTICIPANTS,
      promotionId,
      limit,
      skip,
    ],
    queryFn: async () =>
      await promotionsService.getRidersReferralPromotionParticipants({
        promotionId,
        limit,
        skip,
      }),
  });
};
