import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { corporateClientsService } from "@api/services";
import { CorporateClientCreateBalanceDetailType, IDType } from "@common/Types";

type Params = {
  id: IDType;
  data: CorporateClientCreateBalanceDetailType;
};

export const useCreateOperation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }: Params) =>
      corporateClientsService.createCorporateClientsOperation(data, id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CORPORATE_CLIENTS_DETAIL],
      });
      toast.success(`Операция успешно выполнена`);
    },
  });
};
