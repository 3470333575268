import clsx from "clsx";
import { isArray } from "radash";
import React, { useState } from "react";

import { Button, Grid } from "@components/Elements";

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const MAX_COUNT = 6;

export const DesktopFilter = ({ children }: Props) => {
  const [showMore, setShowMore] = useState(false);

  const slicedChildren = React.useMemo(() => {
    if (!showMore && isArray(children)) {
      return children.slice(0, MAX_COUNT);
    }

    return children;
  }, [showMore]);

  const showButton = isArray(children) && children.length > MAX_COUNT;

  return (
    <Grid
      columns={showButton ? "auto-fit" : "auto-fill"}
      bgColor="neutral-0"
      minColumnWidth="240px"
      className={clsx("rounded-m p-2", {
        "relative pr-24": showButton,
      })}
    >
      {slicedChildren}
      {showButton && (
        <Button
          type="button"
          view="transparent"
          className="w-20 h-auto absolute right-2 top-2 bottom-2"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Скрыть" : "Еще"}
        </Button>
      )}
    </Grid>
  );
};
