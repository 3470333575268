import React from "react";

import { CorporateClientIcon } from "@assets/Icons";
import { Loader, Typography } from "@components/Elements";

type Props = {
  title?: string;
  description?: string;
  loading?: boolean;
  button?: React.ReactNode;
};

export const EmptyPage = ({ title, description, button, loading }: Props) => {
  return (
    <Loader
      loading={loading}
      height={400}
      bordered
      className={"mt-0 card-shadow rounded-[12px"}
    >
      <div
        className={
          "flex flex-col bg-white w-full items-center justify-center z-10 py-20 mt-0 card-shadow rounded-[12px]"
        }
      >
        <CorporateClientIcon className={"mb-[16px]"} />
        <Typography
          as="p"
          className={"text-neutral-text-800 font-semibold mt-[16px]"}
          variant="heading-1-a"
          color={"neutral-900"}
        >
          {title || "Данные по вашему запросу не найдены"}
        </Typography>
        <Typography
          variant="body-2-a"
          color={"neutral-900"}
          className={
            "text-neutral-text-500 font-normal text-center leading-[24px] mt-[4px] w-[450px]"
          }
        >
          {description}
        </Typography>
        <div className={"flex flex-col mt-[16px]"}>{button}</div>
      </div>
    </Loader>
  );
};
