import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { tariffsService } from "@api/services";
import { TariffType } from "@common/Types";

export const useUpdateTariff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tariff: TariffType) => tariffsService.updateTariff(tariff),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TARIFFS_BY_ID] });
    },
  });
};
