import React from "react";

export const ExcelIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8332 10V6.52333C15.8332 6.08167 15.6573 5.6575 15.3448 5.345L12.9882 2.98833C12.6757 2.67583 12.2515 2.5 11.8098 2.5H5.83317C4.91234 2.5 4.1665 3.24583 4.1665 4.16667V10"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8332 6.66667H12.4998C12.0398 6.66667 11.6665 6.29333 11.6665 5.83333V2.5"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="0.833252"
        y="10"
        width="18.3333"
        height="9.16667"
        rx="2"
        stroke="#004F62"
        strokeWidth="1.5"
      />
      <path
        d="M16.5 12.6721C15.6667 12.3943 14 12.3181 14 13.5054C14 15 16.9167 14.1667 16.9167 15.5888C16.9167 16.9632 14.9722 16.6999 14 16.4221"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 12.5L7.08325 16.6667"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.08325 12.5L3.33325 16.6667"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.25 12.4469V16.6666H12.1667"
        stroke="#004F62"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
