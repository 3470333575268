import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { TransactionsService } from "@api/services";
import { BankAccountQuery } from "@common/Types";

export const useGetBankAccounts = (params: BankAccountQuery) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ADMIN_TRANSACTIONS_BANK_ACCOUNTS, params],
    queryFn: async () => await TransactionsService.getBankAccounts(params),
  });
};
