/**
 * Сервисы для corporate-clients
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.corporate_clients;

export const corporateClientsPath = {
  corporate_clients: src,
  corporate_clients_detail: (id: IDType) => `${src}/${id}/`,
  create_employee: (id: IDType) => `${src}/${id}/employees/`,
  corporate_client_employees: (id: IDType) => `${src}/${id}/employees/`,
  operation: (id: IDType) => `${src}/${id}/balance/`,
  corporate_client_employee: (id: IDType, employeeId: IDType) =>
    `${src}/${id}/employees/${employeeId}`,
  corporate_clients_transactions: (id: IDType) => `${src}/${id}/transactions/`,
  corporate_clients_download_reports: (reportId: IDType) =>
    `${src}/reports/${reportId}/download/`,
  corporate_clients_reports: (id: IDType) => `${src}/${id}/reports/`,
};
