import { Field, useField } from "formik";
import { isArray } from "radash";
import React from "react";

import { CommonTypedFieldParams } from "@common/Types";
import { Col, TextInput, TimeInput, Typography } from "@components/Elements";

import { renderTextValue } from "./common/utils";

type Props<T> = {
  params: CommonTypedFieldParams<T>[] | CommonTypedFieldParams<T>;
  isEdit?: boolean;
};

export const FormDescriptionField = <T,>({
  params,
  isEdit = false,
}: Props<T>) => {
  const transformedParams = isArray(params) ? params : [params];

  return (
    <>
      {transformedParams.map(
        (param: CommonTypedFieldParams<T>, index: number) => {
          const {
            name,
            field,
            type,
            inputValue = null,
            disabled = false,
            description,
            ...rest
          } = param;

          const [{ value, onChange }, { touched, error }] = useField(
            String(field),
          );

          const currentValue = inputValue ?? value;

          const handleDescriptionChange = (value: MouseEvent & Date) => {
            switch (type) {
              case "text": {
                return onChange(value);
              }
              case "time": {
                const { onTimeChange } = param;

                return onTimeChange ? onTimeChange(value) : onChange(value);
              }
            }
          };

          return (
            <Col key={`${String(name)}-${index}`} className="gap-1 flex-wrap">
              {!isEdit ? (
                <>
                  <Typography
                    color="neutral-500"
                    variant="body-2-b"
                    as="dt"
                    className="font-medium"
                  >
                    {name}
                  </Typography>

                  <Typography color="neutral-800" as="dd">
                    {renderTextValue({
                      param,
                      currentValue,
                      value,
                    })}
                  </Typography>
                </>
              ) : (
                <Col
                  className="gap-3 w-full h-full"
                  justifyContent="justify-between"
                >
                  {description && (
                    <Typography
                      as="span"
                      color="neutral-500"
                      variant="body-2-a"
                      className="font-medium text-wrap"
                    >
                      {description}
                    </Typography>
                  )}

                  <Field
                    value={currentValue}
                    name={field}
                    label={name}
                    fullWidth
                    placeholder="Введите значение"
                    disabled={disabled}
                    error={(touched && error) || null}
                    onChange={handleDescriptionChange}
                    as={type === "time" ? TimeInput : TextInput}
                    {...rest}
                  />
                </Col>
              )}
            </Col>
          );
        },
      )}
    </>
  );
};
