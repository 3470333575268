import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { parkApplicationsService } from "@api/services";
import { ParkApplicationsParams } from "@common/Types";

type Params = ParkApplicationsParams & {
  enabled?: boolean;
};

export const useGetParkApplications = ({
  filters,
  limit,
  skip,
  enabled,
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PARK_APPLICATIONS, limit, skip, filters],
    queryFn: async () =>
      await parkApplicationsService.getParksApplications({
        filters,
        skip,
        limit,
      }),
    enabled,
  });
};
