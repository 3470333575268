import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { antiFraudsService } from "@api/services";
import {
  FraudControlReasonType,
  ReasonAniFraudsQueryParams,
} from "@common/Types";

type Params = ReasonAniFraudsQueryParams & {
  enabled?: boolean;
};

export const useGetReasonAntiFrauds = ({
  enabled = true,
  ...params
}: Params) => {
  const { data, ...query } = useQuery({
    queryKey: [QUERY_KEYS.ANTI_FRAUDS_REASON, params],
    queryFn: async () => await antiFraudsService.getReasonAntiFraud(params),
    enabled,
  });

  const sortedData = data?.sort(
    (a: FraudControlReasonType, b: FraudControlReasonType) => a.id - b.id,
  );

  return { response: sortedData, ...query };
};
