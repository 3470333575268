import { ReactNode } from "react";

import { FRAUD_STATUSES, PaymentProviderType } from "@common/Constants";

import { IDType } from "./commonTypes";

export type FraudControlStatus =
  | FRAUD_STATUSES.IN_MODERATION
  | FRAUD_STATUSES.BLOCKED
  | FRAUD_STATUSES.UNLOCKED
  | FRAUD_STATUSES.CREATED;

type FraudControlCity = {
  id: number;
  name: string;
};

type FraudControlAdmin = {
  second_name: string | null;
  last_name: string | null;
  id: number;
  phone: string;
  first_name: string;
  is_active: boolean;
};

type FraudControlLastModeration = {
  admin: FraudControlAdmin | null;
  id: number;
  created_at: string;
  admin_id: number | null;
  blocked_payment_id: number;
  description: string;
  set_to_status: string;
};

export type FraudControlDetailType = {
  id: number;
  driver_id: number;
  driver_full_name: string;
  driver_phone: string;
  ride_id: number;
  city: FraudControlCity;
  amount: number;
  time_limit: number | null;
  ride_time: number | null;
  amount_limit: number;
  ride_amount: number;
  ride_started_at: string;
  address: string;
  status: string;
  last_moderation?: FraudControlLastModeration;
  moderation_count: number;
  action?: ReactNode;
};

export type FraudControlReasonType = {
  id: number;
  created_at: string;
  admin_id: number | null;
  blocked_payment_id: number;
  description: string;
  set_to_status: string;
  admin: FraudControlAdmin | null;
};

export type FraudControlType = {
  length: number;
  items: FraudControlDetailType[];
};

export type VerdictFraudControlType = {
  description: string;
};

export type VerdictAniFraudsQueryParams = {
  blocked_payment_id: number | null;
  type: FRAUD_STATUSES | null;
  description: string;
};

export type ReasonAniFraudsQueryParams = {
  paymentId: number | null;
};

export type VerdictModalType = {
  isOpen: boolean;
  id: number | null;
  type: FRAUD_STATUSES | null;
};

export type ReasonModalType = {
  isOpen: boolean;
  id: number | null;
};

export type ReRegistrationUsersDetailType = {
  id: number;
  first_name: string;
  second_name: string | null;
  last_name: string;
  roles: string[];
  iin: string;
  reregistered_at: string;
  deleted_at: string;
  phone: string;
};

export type ReRegistrationUsersType = {
  items: ReRegistrationUsersDetailType[];
  length: number;
};

export type ToggleReRegistrationUserParams = {
  user_id: IDType;
  action: FRAUD_STATUSES | null;
  comment?: string;
};

export type TableReturnValues<RowType> = Array<{
  key?: keyof RowType | null;
  title: string | null;
  render?: (row: RowType) => React.ReactNode;
  thCellClassName?: string;
  tdCellClassName?: string;
  isRemoved?: boolean;
}>;

// FILTERS
export type FraudControlFilterTypes<T> = {
  skip?: number;
  limit?: number;
  city_id?: IDType;
  status: T;
};

export type FraudControlPermissionsFilters =
  FraudControlFilterTypes<FRAUD_PERMISSION_STATUS>;

export type FraudControlRidesFilters = FraudControlFilterTypes<FRAUD_STATUSES>;

// Permissions
export enum FRAUD_PERMISSION_STATUS {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum FRAUD_PERMISSION_ACTION {
  APPROVE = "approve",
  REJECT = "reject",
}

export type FraudControlPermissionsStatusEdit = {
  action: FRAUD_PERMISSION_ACTION;
  comment: string;
  promotion_antifraud_id: IDType;
};

type FraudControlPermissionsRideDriver = {
  id: number;
  user: {
    id: number;
    phone: string;
    first_name: string;
    last_name: string;
    second_name: string;
    is_active: boolean;
  };
};

type FraudControlPermissionsRidePrice = {
  id: number;
  total: number;
  total_without_discount: number;
  ride_time: number;
  used_bonuses: number;
};

type FraudControlPermissionsRide = {
  driver: FraudControlPermissionsRideDriver;
  started_at: string;
  payment_provider: PaymentProviderType;
  price: FraudControlPermissionsRidePrice;
  mileage: number;
  city: {
    id: number;
    name: string;
  };
};

type FraudControlPermissionsModerationAdmin = {
  id: number;
  phone: string;
  first_name: string;
  last_name: string;
  second_name: string;
  is_active: true;
};

type FraudControlPermissionsModeration = {
  action: string;
  admin_user_id: number;
  comment: string;
  promotion_antifraud_id: number;
  admin: FraudControlPermissionsModerationAdmin;
};

export type FraudControlPermissionsDetailType = {
  id: IDType;
  status: string;
  reason: string;
  ride_id: number;
  ride: FraudControlPermissionsRide;
  moderation: FraudControlPermissionsModeration;
};

export type FraudControlPermissionType = {
  length: number;
  items: FraudControlPermissionsDetailType[];
};
