import {
  CameraIcon,
  DriversApplicationIcon,
  DriversIcon,
  HomeIcon,
  NewsIcon,
  RidersIcon,
  RidesIcon,
  StatisticsIcon,
  SupportIcon,
  TaxiCompanyApplicationIcon,
  UsersIcon,
} from "@assets/Icons";
import { FeesPartnerType } from "@common/Types";

import { AccessType, CityDetailTabs } from "../types";

export const WithdrawFormSwitchFields: Partial<
  Record<FeesPartnerType, string>
> = {
  mbank: "withdraw_driver_funds_by_mbank",
  epay: "withdraw_driver_funds_by_epay",
  altyn: "withdraw_driver_funds_by_altyn",
  freedom: "withdraw_driver_funds_by_freedom",
};

export const CITY_DETAIL_TABS: CityDetailTabs[] = [
  { value: "city_data", label: "Данные города" },
  { value: "city_high_demand", label: "Зона повышенного спроса" },
  { value: "city_tariffs", label: "Тарифы" },
  { value: "city_control_panel", label: "Панель управления" },
  { value: "city_access", label: "Доступы" },
];

export const PAGES_ACCESS: AccessType[] = [
  {
    name: "Главная",
    field: "main",
    Icon: HomeIcon,
  },
  {
    collapsible: true,
    name: "Заявки от водителей",
    field: "driver_application_main",
    Icon: DriversApplicationIcon,
    children: [
      {
        name: "Регистрация",
        field: "driver_registration_application",
        children: [
          {
            name: "Новые",
            field: "driver_registration_new_application",
          },
          {
            name: "Отклоненные",
            field: "driver_registration_declined_application",
          },
          {
            name: "Одобренные",
            field: "driver_registration_approved_application",
          },
        ],
      },
    ],
  },
  {
    collapsible: true,
    name: "Заявки от таксопарков",
    field: "park_application_main",
    Icon: TaxiCompanyApplicationIcon,
    children: [
      {
        name: "Новые",
        field: "park_new_application",
      },
      {
        name: "Рассмотренные",
        field: "park_viewed_application",
      },
    ],
  },
  {
    collapsible: true,
    name: "Фотоконтроль",
    field: "photo_control_main",
    Icon: CameraIcon,
    children: [
      {
        name: "Новые",
        field: "photo_control_new",
      },
      {
        name: "Отклоненные",
        field: "photo_control_declined",
      },
      {
        name: "Одобренные",
        field: "photo_control_approved",
      },
    ],
  },
  {
    collapsible: true,
    name: "Поездки",
    field: "rides_main",
    Icon: RidesIcon,
    children: [
      { name: "Отзывы", field: "rides_reviews" },
      { name: "По пути", field: "rides_destinations" },
    ],
  },
  {
    collapsible: true,
    name: "Водители",
    field: "drivers_main",
    Icon: DriversIcon,
    children: [
      { name: "Активные", field: "drivers_active" },
      { name: "Заблокированные", field: "drivers_blocked" },
      { name: "Профиль", field: "driver_profile" },
      { name: "История заказов", field: "driver_profile_rides_history" },
      { name: "Статистика", field: "driver_profile_statistics" },
      { name: "Транзакции", field: "driver_profile_transactions" },
      {
        name: "История изменений",
        field: "driver_profile_change_history",
        children: [
          {
            name: "Отображать бонусы",
            field: "driver_profile_bonus_view",
          },
          {
            name: "Возможность пополнять бонусы",
            field: "driver_profile_bonus_update",
          },
          {
            name: "Возможность снятия бонусов",
            field: "driver_profile_bonus_delete",
          },
          {
            name: "Отображать баланс",
            field: "driver_profile_balance_view",
          },
          {
            name: "Возможность пополнять баланс",
            field: "driver_profile_balance_update",
          },
          {
            name: "Возможность снятия баланса",
            field: "driver_profile_balance_delete",
          },
        ],
      },
    ],
  },
  {
    collapsible: true,
    name: "Клиенты",
    field: "riders_main",
    Icon: RidersIcon,
    children: [
      { name: "Активные", field: "riders_active" },
      { name: "Заблокированные", field: "riders_blocked" },
    ],
  },
  {
    collapsible: true,
    name: "Таксопарк",
    field: "parks_main",
    Icon: TaxiCompanyApplicationIcon,
    children: [{ field: "parks_create", name: "Добаление таксопарка" }],
  },
  {
    collapsible: true,
    name: "Новости",
    field: "news_main",
    Icon: NewsIcon,
    children: [
      { name: "Новости в приложениях", field: "news_mobile" },
      { name: "Новости на сайте", field: "news_web" },
    ],
  },
  {
    collapsible: true,
    name: "Статистика",
    field: "statistics_main",
    Icon: StatisticsIcon,
    children: [{ name: "Конверсия", field: "statistics_conversion" }],
  },
  {
    collapsible: true,
    name: "Чат поддержки",
    field: "chat",
    Icon: SupportIcon,
    children: [
      { name: "Водители", field: "chat_with_drivers" },
      { name: "Пассажиры", field: "chat_with_riders" },
    ],
  },
  {
    collapsible: true,
    name: "Пользователи",
    field: "settings_main",
    Icon: UsersIcon,
    children: [
      { name: "Контроль версий", field: "settings_versions_update" },
      { name: "Бонусы", field: "settings_bonuses" },
      { name: "Интро", field: "settings_intro_update" },
    ],
  },
];
