import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionParams } from "@common/Types";

export const useGetPromotionParksParticipants = (params: PromotionParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PROMOTION_PARKS_PARTICIPANTS, params.promotionId],
    queryFn: async () =>
      await promotionsService.getPromotionParksParticipants(params),
  });
};
