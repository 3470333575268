import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { ridesService } from "@api/services";
import { IDType } from "@common/Types";

type Params = {
  rideId: IDType;
};

export const useGetRideDetail = ({ rideId }: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDE_DETAIL, rideId],
    queryFn: async () => await ridesService.getRideDetail(rideId),
  });
};
